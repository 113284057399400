import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphQLClient } from '../../services';
import { map } from 'rxjs/operators';
import { ImageContentDocument } from "../../graphql";
import { ImageDto } from "../../domain";


@Injectable()
export class ImagesServiceGql {

	constructor(private client: GraphQLClient) {
	}

	get(imageId: number): Observable<ImageDto> {
		return this.client.request(ImageContentDocument, { id: imageId })
			.pipe(map(result => (<any>result).ImageContent as ImageDto));
	}
}
