import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

// TODO localize maxduration error message args.type
// const durationsI18n = {
// 	year: $localize`year`,
// 	quarter: $localize`quarter`,
// 	month: $localize`month`,
// 	week: $localize`week`,
// 	day: $localize`day`,
// 	hour: $localize`hour`,
// 	minute: $localize`minute`,
// 	second: $localize`second`,
// 	millisecond: $localize`millisecond`,
// }

@Pipe({
	standalone: true,
	name: 'readableErrors',
})
export class ValidationMessagePipe implements PipeTransform {

	private static defaultMsg = $localize`is invalid!`;

	private static errorMessages: {[key: string]: (args?: any)=> string} = {
		// errors will show according to their priority - one at a time (required has highest priority)
		// e.g. if we have required email field, user will be first notified that it is required,
		// and if an invalid value is put, the a notification for invalid email will be shown
		required: () => $localize`is required!`,
		min: (args: any) => $localize`must be greater than ${args.min}`,
		max: (args: any) =>	$localize`must be less than ${args.max}`,
		minLength: (args: any) => $localize`length must	be at least ${args.requiredLength}`,
		maxLength: (args: any) =>	$localize`length must be at most ${args.requiredLength}`,
		maxDuration: (args: any) => $localize`is invalid! Max duration is ${args.count} ${args.type}`,
		email: () => ValidationMessagePipe.defaultMsg, // may be add more meaningfull message
		pattern: () => ValidationMessagePipe.defaultMsg, // may be add more meaningfull message
	}

	constructor() {}

	public transform(errors: ValidationErrors, showAll = false): string {
		if (!(errors && Object.keys(errors).length)) {
			return null;
		}

		// TODO: implement `showAll` behavior !?!
		if (showAll) {
			throw "`showAll` behavior is not implemented yet"
		}
		const first = Object.entries(errors)[0];

		// (custom validators shoud provide `message` as object field...
		if (first[1].message) {
			return first[1].message;
		}

		// generic validator - interpolate message
		if (ValidationMessagePipe.errorMessages[first[0]]) {
			return ValidationMessagePipe.errorMessages[first[0]](errors[first[0]]);
		}

		// ... or string value as error message)
		return first[1] || ValidationMessagePipe.defaultMsg;
	}
}
