import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppMainComponent } from '../app-main/app-main.component';
import { SettingsService } from '../../../services';
import { SETTINGS_CONTEXT } from '../../../shared';

@Component({
	selector: 'app-config',
	templateUrl: './app-config.component.html',
	styleUrls: ['./app-config.component.scss'],
	animations: [
		trigger('children', [
			state('hiddenAnimated', style({
				opacity: 0,
				transform: ' translateX(-50%) translateY(-50%)'
			})),
			state('visibleAnimated', style({
				opacity: 1,
				transform: 'translateX(-50%) translateY(-50%) scale(1)',
			})),
			transition('visibleAnimated => hiddenAnimated', animate('500ms cubic-bezier(0, 0, 0.2, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('500ms cubic-bezier(0, 0, 0.2, 1)'))
		])
	],
	providers: [
		{ provide: SETTINGS_CONTEXT, useValue: "mirage_theme_settings" },
		SettingsService,
	]
})
export class AppConfigComponent implements OnInit {

	private readonly _plainColors = [
		{ name: $localize `Blue`, file: 'blue', image: 'blue.svg' },
		{ name: $localize `Green`, file: 'green', image: 'green.svg' },
		{ name: $localize `Yellow`, file: 'yellow', image: 'yellow.svg' },
		{ name: $localize `Cyan`, file: 'cyan', image: 'cyan.svg' },
		{ name: $localize `Purple`, file: 'purple', image: 'purple.svg' },
		{ name: $localize `Orange`, file: 'orange', image: 'orange.svg' },
		{ name: $localize `Teal`, file: 'teal', image: 'teal.svg' },
		{ name: $localize `Magenta`, file: 'magenta', image: 'magenta.svg' },
		{ name: $localize `Lime`, file: 'lime', image: 'lime.svg' },
		{ name: $localize `Brown`, file: 'brown', image: 'brown.svg' },
		{ name: $localize `Red`, file: 'red', image: 'red.svg' },
		{ name: $localize `Indigo`, file: 'indigo', image: 'indigo.svg' },
	];
	readonly customColors = [
		{ name: 'Chile', file: 'chile', image: 'chile.png' },
		{ name: 'Naples', file: 'naples', image: 'naples.png' },
		{ name: 'Georgia', file: 'georgia', image: 'georgia.png' },
		{ name: 'Infinity', file: 'infinity', image: 'infinity.png' },
		{ name: 'Chicago', file: 'chicago', image: 'chicago.png' },
		{ name: 'Majesty', file: 'majesty', image: 'majesty.png' },
		{ name: 'Fish', file: 'fish', image: 'fish.png' },
		{ name: 'Dawn', file: 'dawn', image: 'dawn.png' },
		{ name: 'Record', file: 'record', image: 'record.png' },
		{ name: 'Pool', file: 'pool', image: 'pool.png' },
		{ name: 'Metal', file: 'metal', image: 'metal.png' },
		{ name: 'China', file: 'china', image: 'china.png' },
		{ name: 'Table', file: 'table', image: 'table.png' },
		{ name: 'Panorama', file: 'panorama', image: 'panorama.png' },
		{ name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
		{ name: 'Underwater', file: 'underwater', image: 'underwater.png' },
		{ name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
		{ name: 'Rain', file: 'rain', image: 'rain.png' },
		{ name: 'Utah', file: 'utah', image: 'utah.png' },
		{ name: 'Wave', file: 'wave', image: 'wave.png' },
		{ name: 'Flora', file: 'flora', image: 'flora.png' },
		{ name: 'Speed', file: 'speed', image: 'speed.png' },
		{ name: 'Canopy', file: 'canopy', image: 'canopy.png' },
		{ name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
		{ name: 'Basketball', file: 'basketball', image: 'basketball.png' },
		{ name: 'Misty', file: 'misty', image: 'misty.png' },
		{ name: 'Summit', file: 'summit', image: 'summit.png' },
		{ name: 'Wall', file: 'wall', image: 'wall.png' },
		{ name: 'Ferris', file: 'ferris', image: 'ferris.png' },
		{ name: 'Ship', file: 'ship', image: 'ship.png' },
		{ name: 'NY', file: 'ny', image: 'ny.png' },
		{ name: 'Cyan', file: 'cyan', image: 'cyan.png' },
		{ name: 'Violet', file: 'violet', image: 'violet.png' },
		{ name: 'Red', file: 'red', image: 'red.png' },
		{ name: 'Blue', file: 'blue', image: 'blue.png' },
		{ name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
		{ name: 'Pink', file: 'pink', image: 'pink.png' },
		{ name: 'Purple', file: 'purple', image: 'purple.png' },
		{ name: 'Orange', file: 'orange', image: 'orange.png' },
	];
	readonly menuColors = [
		{ name: 'light' },
		{ name: 'dark' },
		{ name: 'custom' },
	];
	
	darkColors: any;
	lightColors: any;
	selectedColorOptions: any;
	componentThemes: any;

	constructor(public appMain: AppMainComponent, private settings: SettingsService) {
	}

	ngOnInit() {
		this.settings.onChanges$.subscribe(settings => {
			if (settings) {
				this.restoreSettings(settings);
			}
		});

		this.darkColors = this._plainColors;
		this.lightColors = this._plainColors;
		this.componentThemes = this._plainColors;
		this.selectedColorOptions = this.lightColors;
	}
	
	private restoreSettings(settings: any) {
		this.appMain.horizontalMenu = settings.horizontalMenu;
		this.appMain.staticMenuActive = settings.staticMenuActive;
		this.appMain.inputStyle = settings.inputStyle;
		this.appMain.onRippleChange({checked: settings.ripple});
		
		this.appMain.darkMode = settings.darkMode;
		this.appMain.menuColorMode = settings.menuColorMode;
		this.appMain.menuColor = settings.menuColor;
		this.appMain.layoutColor = settings.layoutColor;
		this.appMain.themeColor = settings.themeColor;
		this.changeLightDarkLayout('layout-css', 'layout-' + (settings.darkMode ? 'dark' : 'light'), settings.layoutColor,);
		this.changeLightDarkTheme('theme-css', 'theme-' + (settings.darkMode ? 'dark' : 'light'), this.appMain.themeColor);
	}

	changeLayout(event: Event, mode: boolean) {
		this.appMain.darkMode = mode;
		if (mode === true) {
			this.appMain.menuColorMode = 'dark';
			this.appMain.menuColor = 'layout-menu-dark';
			this.selectedColorOptions = this.darkColors;
			this.appMain.layoutColor = this.selectedColorOptions[0].file;
			this.changeLightDarkLayout('layout-css', 'layout-dark', this.selectedColorOptions[0].file,);
			this.changeLightDarkTheme('theme-css', 'theme-dark');
		} else {
			this.appMain.menuColorMode = 'light';
			this.appMain.menuColor = 'layout-menu-light';
			this.selectedColorOptions = this.lightColors;
			this.appMain.layoutColor = this.selectedColorOptions[0].file;
			this.changeLightDarkLayout('layout-css', 'layout-light', this.selectedColorOptions[0].file,);
			this.changeLightDarkTheme('theme-css', 'theme-light');
		}

		event.preventDefault();
	}
	
	changeLightDarkTheme(id: string, mode: string, color?: string) {
		const element = document.getElementById(id)!;
		const urlTokens = element.getAttribute('href')!.split('/');
		if (color) {
			urlTokens[urlTokens.length - 2] = color;
		}
		urlTokens[urlTokens.length - 1] = mode + '.css';
		const newURL = urlTokens.join('/');

		this.replaceLink(element, newURL);
	}

	changeLightDarkLayout(id: string, mode: string, color: string,) {
		const element = document.getElementById(id)!;
		const urlTokens = element.getAttribute('href')!.split('/');
		urlTokens[urlTokens.length - 2] = color;
		urlTokens[urlTokens.length - 1] = mode + '.css';
		const newURL = urlTokens.join('/');

		this.replaceLink(element, newURL);
	}
	
	changeMenuToHorizontal(event: Event, mode: boolean) {
		this.appMain.horizontalMenu = mode;
		event.preventDefault();
	}

	changeMenuColor(event: Event, mode: string) {
		this.appMain.menuColorMode = mode;
		if (mode !== 'custom') {
			this.appMain.menuColor = 'layout-menu-' + mode;
			if (mode === 'dark') {
				this.selectedColorOptions = this.darkColors;
				this.appMain.layoutColor = this.selectedColorOptions[0].file;
				this.changeStyleSheetsColor('layout-css', this.selectedColorOptions[0].file);
			} else {
				this.selectedColorOptions = this.lightColors;
				this.appMain.layoutColor = this.selectedColorOptions[0].file;
				this.changeStyleSheetsColor('layout-css', this.selectedColorOptions[0].file);
			}
		} else {
			this.appMain.menuColor = 'layout-menu-' + this.customColors[0].file;
			this.selectedColorOptions = this.customColors;
		}
		event.preventDefault();
	}

	changeMenuTheme(event: Event, color: string) {
		if (this.appMain.menuColorMode !== 'custom') {
			this.changeStyleSheetsColor('layout-css', color);
			this.appMain.layoutColor = color;
		} else {
			this.appMain.menuColor = 'layout-menu-' + color;
		}
		event.preventDefault();
	}

	changeComponentTheme(event: Event, color: string) {
		this.appMain.themeColor = color;
		this.changeStyleSheetsColor('theme-css', color);

		event.preventDefault();
	}

	changeStyleSheetsColor(id: string, value: string) {
		const element = document.getElementById(id)!;
		const urlTokens = element.getAttribute('href')!.split('/');
		urlTokens[urlTokens.length - 2] = value;
		const newURL = urlTokens.join('/');

		this.replaceLink(element, newURL);
	}

	onConfigButtonClick(event: Event) {
		this.appMain.configActive = true;
		event.preventDefault();
	}

	onConfigCloseClick(save: boolean) {
		if (save) {
			this.settings.set({
				horizontalMenu: this.appMain.horizontalMenu,
				darkMode: this.appMain.darkMode,
				menuColorMode: this.appMain.menuColorMode,
				menuColor: this.appMain.menuColor,
				themeColor: this.appMain.themeColor,
				layoutColor: this.appMain.layoutColor,
				staticMenuActive: this.appMain.staticMenuActive,
				inputStyle:this.appMain.inputStyle,
				ripple: this.appMain.ripple
			});
		} else {
			//reload current settings
			this.settings.load();
		}
		this.appMain.configActive = false;
	}


	replaceLink(linkElement: HTMLElement, href: string) {
		if (this.isIE()) {
			linkElement.setAttribute('href', href);
		}
		else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode!.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id!);
			});
		}
	}

	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
	}
}
