import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	Router,
	RouterModule,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';

import { TabsService } from '../tabs.service';
import { SettingsService } from '../../../services';
import { AsyncPipe } from '@angular/common';

@Component({
	standalone: true,
	selector: 'br-tabs',
	imports: [
		AsyncPipe,
		RouterModule,
		TabViewModule,
		ButtonModule,
		TooltipModule,
		CardModule,
	],
	providers: [
		// SETTINGS_CONTEXT must be provided by route.providers or root element
		SettingsService,
		TabsService,
	],
	templateUrl: './tab-container.component.html',
	styles: [
		`
			.p-tabview .p-tabview-panels {
				padding: 0px;
			}
			.p-card {
				.p-card-body {
					padding-top: 0px;
				}
				.p-card-content1 {
					padding: 0px;
				}
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class TabContainerComponent implements OnInit, OnDestroy {
	openedTabs$: Observable<MenuItem[]>;
	subscriptions = new Subscription();
	selectedIndex = 0;

	constructor(
		protected router: Router,
		protected route: ActivatedRoute,
		protected tabSvc: TabsService
	) {}

	public ngOnInit() {
		this.router.events.subscribe((event) => {
			if (
				event instanceof NavigationEnd &&
				(event.urlAfterRedirects.match(/\//g) || []).length === 1
			) {
				// Check for parent route. If is /doctors or /patients etc., then null inner selected tabs;
				this.selectedIndex = 0;
			};
			if (event instanceof NavigationEnd && this.router.url.endsWith('/0')) {
				// If is new entry - change active tab to 0 for good work of redirection after submit.
				this.selectedIndex = 0;
			}
		});
		this.openedTabs$ = this.tabSvc.openedTabs;
		this.addSelectionListener(this.onTabChange.bind(this));
	}

	private onTabChange(idx: number) {
		if (idx === null) {
			// closing active, last or all tabs
			this.handleChange({ index: 0 });
		} else {
			this.selectedIndex = idx + 1;
		}
	}

	addSelectionListener(fn: any) {
		this.subscriptions.add(this.tabSvc.selectedTab.subscribe(fn));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	handleClose(e: {
		originalEvent?: Event;
		index: number;
		close?: () => void;
	}) {
		this.tabSvc.closeTabByIndex(e.index - 1);
	}

	handleChange(e: { originalEvent?: Event; index: number }) {
		if (e.index === 0) {
			this.selectedIndex = 0;
			this.router.navigate(['./'], { relativeTo: this.route });
			return;
		}
		this.router.navigate(
			['./', this.tabSvc.getTabByIndex(e.index - 1).id],
			{ relativeTo: this.route }
		);
	}

	closeAll() {
		// close all tabs, remove items from storage etc. and navigate to list
		this.tabSvc.closeAllTabs();
	}
}
