import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
	name: 'toAge',
	standalone: true,
})
export class ToAge implements PipeTransform {

	public transform(value: string, atDate?: string): number {
		return ToAge.transform(value, atDate);
	}

	public static transform (value: string | Date, atDate?: string): number {
		if (!value) {
			return null;
		}
		let age = 0;
		let obj: any = value;
		if (value instanceof Date) {
			obj = DateTime.fromJSDate(value);
		} else {
			obj = DateTime.fromISO(value);
		}
		if (!atDate) {
			age = obj.diffNow('years').years;
		} else {
			age = obj.diff(DateTime.fromISO(atDate), 'years').years;
		}

		age = Math.floor(Math.abs(age));
		if (isNaN(age)) {
			age = null;
		}
		return age;
	}
}
