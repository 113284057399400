import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { Pattern } from '../../domain';
import { MailerInputData, MailerSendData } from './mailer-data';
import { MailerServiceGql } from './mailer.service.qgl';

@Component({
	standalone: true,
	selector: 'br-mailer',
	templateUrl: './mailer.component.html',
	styleUrls: ['./mailer.component.scss'],
	imports: [
		ReactiveFormsModule,
		DialogModule,
		EditorModule,
		DropdownModule,
		InputTextModule,
		ButtonModule,
	],
	providers: [MailerServiceGql],
})
export class MailerComponent implements OnInit {
	@Input()
	visible: boolean = true;
	@Input()
	data: MailerInputData;

	@Output()
	close: EventEmitter<null> = new EventEmitter();
	@Output()
	emailSent: EventEmitter<null> = new EventEmitter();

	patterns: Pattern[];
	fg: FormGroup;

	constructor(private svc: MailerServiceGql, private mSvc: MessageService) {}

	ngOnInit() {
		if (this.data.patternModel) {
			this.svc
				.getMailerPatterns(this.data.patternModel)
				.subscribe((patterns) => (this.patterns = patterns));
		}
		this.fg = new FormGroup({
			recipient: new FormControl(this.data?.recipient, [
				Validators.required,
				Validators.email,
			]),
			emailSubject: new FormControl(null),
			emailContent: new FormControl(null),
		});
	}

	setEmailContent(event: any) {
		if (!event.value.content) {
			return;
		}
		const matches = event.value.content.match(/\{\{([^\{\}]+)\}\}/g);
		if (matches && this.data.model) {
			matches.forEach((match: string) => {
				let props = match
					.replace(/\{|\}|model\./g, '')
					.trim()
					.split('.');
				let val: any = this.data.model;
				props.forEach((p) => {
					val = val[p];
				});
				event.value.content = event.value.content.replace(match, val);
			});
		}
		this.fg.controls['emailContent'].setValue(event.value.content);
	}

	hide(event: any) {
		this.fg.reset();
		this.close.emit(event);
	}

	send() {
		this.fg.markAllAsTouched();
		if (this.fg.invalid) {
			return;
		}
		const data: MailerSendData = { ...this.fg.value };
		if (this.fg.value.emailSubject instanceof Object) {
			data.emailSubject = this.fg.value.emailSubject.name;
		}
		data.pdfContent = this.data.pdfContent;
		this.svc.send(data).subscribe((result) => {
			if (result) {
				this.mSvc.add({
					severity: 'success',
					summary: $localize`Success!`,
					detail: $localize`E-mail has been sent!`,
				});
				this.emailSent.emit();
				this.visible = false;
			} else {
				this.mSvc.add({
					severity: 'error',
					summary: $localize`Error!`,
					detail: $localize`E-mail has not been sent!`,
				});
			}
		});
	}
}
