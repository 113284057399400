import { Pipe } from '@angular/core';

// Tell Angular2 we're creating a Pipe with TypeScript decorators
@Pipe({
	name: 'truncate',
	standalone: true
})
export class TruncatePipe {
	public transform(value: string, size: number) {
		return TruncatePipe.format(value, size);
	}

	public static format(value: string, size: number) {
		if (value && value.length > size) {
			return value.substring(0, size) + '...';
		}
		return value;
	}
}
