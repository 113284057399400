import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { FilterMetadata } from 'primeng/api';
import { DateTime, DurationLikeObject } from 'luxon';

import { MatchMode } from '../../../domain';
import { AbstractFilterComponent } from '../abstract';
import { FloatLabelComponent } from '../../forms';
import { MaxDurationValidatorDirective } from '../../common/directives/max-duration-validator.directive';

@Component({
	standalone: true,
	selector: 'br-period-filter',
	templateUrl: './period-filter.component.html',
	imports: [FormsModule, FloatLabelComponent, CalendarModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PeriodFilterComponent),
			multi: true,
		},
	],
	hostDirectives: [
		{
			directive: MaxDurationValidatorDirective,
			inputs: ['maxDurationValidator'],
		},
	]
})
export class PeriodFilterComponent extends AbstractFilterComponent {

	@Input()
	label: string = $localize`Period`;

	@Input()
	icon: string;

	@Input()
	required: boolean = false;

	@Input()
	maxDate = new Date();

	@Input()
	minDate = new Date(1900, 0, 1);

	@Input()
	maxDurationValidator: DurationLikeObject;

	@Input()
	showIcon: boolean = true;

	@Input()
	showButtonBar: boolean = false;

	/** If true, return ISO-compliant string representation of datetime */
	@Input()
	toISO: boolean = false;

	@Input()
	name: string;

	@Input()
	override matchMode: string | MatchMode = MatchMode.Between;

	private readonly fmt: string = 'yyyy-MM-dd';

	override writeValue(model: FilterMetadata): void {
		model = { ...model };
		if (model?.value && model.value.length) {
			let inValue = model.value;
			// format inbound value
			if (!(inValue instanceof Array)) {
				inValue = [inValue];
			}
			if (!inValue[1]) {
				inValue[1] = inValue[0];
			}
			model.value = [...inValue].map(this.stringToDate.bind(this));
		}
		super.writeValue(model);
		// as we most often change value during the initialization
		// we will call onValueChange here
		//this.onValueChange(this.value);
	}

	protected override onChangeHandler(value: Date[]) {
		if (this.model.value != value) {
			this.model.value = value;
			// we will not emit each change, but final value after closing calendar
		}
	}

	/** Emit final filter value only after closing calendar */
	onCalendarClose() {
		if (!this.model.value || !this.model.value.length) {
			this.onModelChange(null);
			return;
		}
		let outModel = { ...this.model };
		if (!outModel.value[1]) {
			outModel.value[1] = outModel.value[0];
		}
		if (this.toISO) {
			outModel.value[1].setDate(outModel.value[1].getDate() + 1);
			outModel.value[1].setMilliseconds(
				outModel.value[1].getMilliseconds() - 1
			);
			outModel.value = outModel.value.map((v: Date) =>
				DateTime.fromJSDate(v).toISO()
			);
		} else {
			outModel.value = outModel.value.map((v: Date) =>
				DateTime.fromJSDate(v).toFormat(this.fmt)
			);
		}

		this.onModelChange(outModel);
	}

	private stringToDate(str: string): Date {
		return this.toISO
			? DateTime.fromISO(str).toJSDate()
			: DateTime.fromFormat(str, this.fmt).toJSDate();
	}
}
