<p-card>
	<ng-template pTemplate="header">
		@if (openedTabs$ | async; as openedTabs) {
			<div class="p-2">
				@if (openedTabs.length) {
					<div class="relative h-3rem">
						<div class="absolute left-0">
							<p-button id="btnList" label=" " icon="mdi mdi-backburger"
								styleClass="ml-2 z-2 p-button-outlined tab-button" pTooltip="List" i18n-pTooltip
								tooltipPosition="bottom" (click)="handleChange({ originalEvent: $event, index: 0 })"></p-button>
						</div>
						<div class="absolute right-0">
							<p-button id="btnCloseAll" icon="mdi mdi-close-box-multiple-outline" label=" "
								styleClass="mr-2 z-2 p-button-outlined tab-button" pTooltip="Close all" i18n-pTooltip
								tooltipPosition="bottom" (click)="closeAll()"></p-button>
						</div>
						<p-tabView [scrollable]="openedTabs.length > 2" [controlClose]="true" (onClose)="handleClose($event)"
							(onChange)="handleChange($event)" [activeIndex]="selectedIndex"
							[styleClass]="'mt-1 mr-7 ml-7 tab-container'">
							<p-tabPanel [headerStyleClass]="'hidden'"></p-tabPanel>
							<!-- - - - - - - - - - - - opened tabs - - - - - - - - - - -  -->
							@for (item of openedTabs; track item) {
								<p-tabPanel [header]="item.label" [closable]="true">
								</p-tabPanel>
							}
						</p-tabView>
					</div>
				}
			</div>
		}
	</ng-template>
	<ng-template pTemplate="content">
		<router-outlet></router-outlet>
	</ng-template>
</p-card>
