import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtils } from 'primeng/utils';

@Pipe({
	standalone: true,
	name: 'resolveField'
})

export class ResolveFieldPipe implements PipeTransform {

	public transform(value: any, prop: any) {
		return ObjectUtils.resolveFieldData(value, prop);
	}
}