import { Pattern } from "../../../domain";
import { Injectable } from "@angular/core";

/**
 * @description
 * Dummy class for custom processing of model based on pattern.
 *
 * Override class and provide it in a parent component of PatternComponent when
 * custom processing of model based on pattern is needed.
 *
 * @see PatternComponent
 * @publicApi
 */
@Injectable()
export class PatternsProcessorService {
	/** Method is called right after pattern is fetched */
	process(pattern: Pattern, model: any) {
	}
}