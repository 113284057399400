import { Pipe, PipeTransform } from '@angular/core';
import { dateFormat } from '../../../shared';

@Pipe({
	name: 'dateFormat',
	standalone: true
})
export class DateFormat implements PipeTransform {
	public transform(date: string | Date, format: string, fromFormat?: string): any {
		return dateFormat(date, format, fromFormat);
	}
}
