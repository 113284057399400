<button pButton icon="mdi mdi-view-column" class="p-button-secondary" (click)="overlay.toggle($event)"></button>
<p-overlayPanel [dismissable]="true" #overlay>
	<p-orderList class="noControls"
		[value]="model"
		[(selection)]="selectedColumns"
		(onSelectionChange)="applySelection()"
		[dragdrop]="true"
		[metaKeySelection]="false"
	>
		<ng-template pTemplate="header">
			<p-triStateCheckbox [(ngModel)]="masterSelect" checkboxFalseIcon="mdi mdi-square-medium"></p-triStateCheckbox>
			<span class="ml-2" i18n>Select all</span>
		</ng-template>
		<ng-template pTemplate="item" let-item>
			<p-checkbox [ngModel]="selectedColumns" [value]="item" name="columns"></p-checkbox>
			<span class="ml-2">{{ item.header }}</span>
		</ng-template>
	</p-orderList>
	<div class="flex justify-content-center mt-3">
		<button pButton class="mdi mdi-content-save-settings" i18n (click)="saveSelection();overlay.hide()">Save</button>
		<button pButton class="mdi mdi-cancel ml-2" i18n (click)="cancel();overlay.hide()">Cancel</button>
	</div>
</p-overlayPanel>