<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}" (click)="appMain.onSidebarClick($event)"
  (mouseover)="appMain.sidebarActive=true" (mouseleave)="appMain.sidebarActive=false">
  <div class="sidebar-logo">
    <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="appMain.onToggleMenuClick($event)">
      @if (appMain.staticMenuActive) {
        <i class="mdi mdi-lock-outline"></i>
      } @else {
        <i class="mdi mdi-lock-open-variant-outline"></i>
      }
    </a>
    <a
      class="logo mt-2 cursor-pointer"
      [class.light]="appMain.menuColorMode == 'light'"
      [class.full]="appMain.sidebarActive || appMain.staticMenuActive"
      [class.powered]="powered"
      (click)="appMain.onLogoClick.next($event)"
    >&nbsp;</a>
  </div>
  <div class="layout-menu-container" (click)="onMenuClick()">
    <ul class="layout-menu">
      @for (item of model; track item; let i = $index) {
        <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
      }
    </ul>
  </div>
</div>
