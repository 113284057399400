import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { print } from "graphql";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_BACKEND_URL } from "../shared";


@Injectable()
export class GraphQLClient {

	constructor(private httpClient: HttpClient, @Inject(BASE_BACKEND_URL) private url: string) {
	}

	request<Q, V = {[key: string]: any}>(doc?: TypedDocumentNode<Q, V>, variables?: V): Observable<Q> {
		return this.httpClient.post<{data: Q}>(this.url + '/graphql', {query: print(doc), variables: variables })
			.pipe(map(result => result.data));
	}
}
