import { Component } from '@angular/core';
import { AppMainComponent } from '../app-main/app-main.component';

@Component({
	selector: 'app-rightpanel',
	templateUrl: './app-rightpanel.component.html'
})
export class AppRightPanelComponent {
	constructor(public appMain: AppMainComponent) {

	}
}
