<p-table [columns]="columns" [filters]="filters" [lazy]="true" (onLazyLoad)="loadData($event)"
	[lazyLoadOnInit]="!settingsContext" [value]="($data | async)?.items" dataKey="id"
	[totalRecords]="($data | async)?.pageInfo.totalCount" [selectionMode]="selectionMode!" [(selection)]="selection"
	[stateKey]="settingsContext" [stateConfig]="stateConfig" serviceStorage (onStateRestore)="onStateRestore($event)"
	[paginator]="true" [rows]="rpp" [rowsPerPageOptions]="rppOptions" [showCurrentPageReport]="true"
	responsiveLayout="scroll" [autoLayout]="true" [resizableColumns]="resizable" (onRowSelect)="rowSelected($event)"
	(onRowUnselect)="rowUnselected($event)">
	@if (caption) {
	<ng-template pTemplate="caption">
		<div class="text-center">
			<span>{{ caption }}</span>
			@if (columnsSelector) {
				<br-columns-selector [(ngModel)]="columns" class="absolute right-0 top-0 mt-1 mr-3" />
			}
		</div>
	</ng-template>
	}
	<ng-template pTemplate="emptymessage" let-columns>
		<tr>
			<td [attr.colspan]="columns?.length + 2" i18n class="font-bold">No records found!</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th class="md:rev:hidden max-w-max p-sortable-column" (click)="clearSort()">
				#
				<i class="pi pi-sort-alt-slash p-sortable-column-icon cursor"></i>
			</th>
			@if (extraFirstCol) {
				<th>&nbsp;</th>
			}
			@for (col of columns; track col) {
				@if (col.visible) {
					<th pResizableColumn [pSortableColumn]="col.sortable">
						<div [pTooltip]="col.headerTooltip?.value ?? col.headerTooltip" [escape]="col.headerTooltip?.escape"
							[tooltipPosition]="col.headerTooltip?.position || 'top'" class="text-center inline-block">
							@if (col.headerIcon && col.headerIcon.position != 'right') {
								<i [ngClass]="col.headerIcon.value ?? col.headerIcon"></i>
							}
							@if (col.header) {
								{{ col.header }}
							}
							@if (col.headerIcon?.position === 'right') {
								<i [ngClass]="col.headerIcon.value"></i>
							}
						</div>
						@if (col.sortable) {
							<p-sortIcon [field]="col.sortable" />
						}
					</th>
				}
			}
			@if (extraLastCol) {
				<th>&nbsp;</th>
			}
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item let-idx="rowIndex" let-columns="columns">
		<tr [pSelectableRow]="item" [pSelectableRowIndex]="idx">
			<td class="md:rev:hidden w-1">{{ idx + 1}}</td>
			@if (extraFirstCol) {
				<td>
					<ng-container [ngTemplateOutlet]="extraFirstCol" [ngTemplateOutletContext]="{ $implicit: item }" />
				</td>
			}
			@for (col of columns; track col) {
				@if (col.visible) {
					<td class="min-w-min" [ngClass]="fieldResolver(item, col.styleClass)">
						<div [ngStyle]="{'cursor': col.overlayContent ? 'help' : 'inherit'}"
							(click)="col.overlayContent && fullContent.toggle($event)"
							[pTooltip]="col.tooltip ? (item | resolveField : (col.tooltip.value || col.tooltip)) : null"
							[escape]="col.tooltip?.escape" [tooltipPosition]="col.tooltip?.position || 'top'"
							[ngClass]="{'text-center': !col.field}" style="white-space: pre">
							@if(col.key === 'aadeStatus' && item.aadeStatus === aadeStatus.Sync) {
								<i [ngClass]="item | resolveField: (col.icon.value || col.icon)"
									(click)="sendToAade($event, item)"></i>
							}
							@else if (col.icon && col.icon.position != 'right') {
								<i [ngClass]="item | resolveField: (col.icon.value || col.icon)"></i>
							}
							@if (!col.htmlContent) {
								<ng-container>{{ item | resolveField: col.field }}</ng-container>
							} @else {
								<span [innerHTML]= "item | resolveField: col.htmlContent | stopSanitize:'html'"> </span>
							}
							@if (col.icon?.position === 'right') {
								<i [ngClass]="item | resolveField: col.icon.value"></i>
							}
							@if (col.icon) {
								<i [ngClass]="col.icon?.value || col.icon"></i>
							}
							<p-overlayPanel #fullContent [dismissable]="true">
								<div [innerHTML]="item | resolveField: col.overlayContent"></div>
							</p-overlayPanel>
						</div>
					</td>
				}
			}
			@if (extraLastCol) {
				<td>
					<ng-container [ngTemplateOutlet]="extraLastCol" [ngTemplateOutletContext]="{ $implicit: item }" />
				</td>
			}
		</tr>
	</ng-template>
</p-table>
