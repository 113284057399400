import { Component } from '@angular/core';
import { MenuService } from '../app-menu/app-menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-main',
	templateUrl: './app-main.component.html',
	animations: [
		trigger('mask-anim', [
			state('void', style({
				opacity: 0
			})),
			state('visible', style({
				opacity: 0.8
			})),
			transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
		])
	],
})
export class AppMainComponent {

	horizontalMenu: boolean = false;
	darkMode: boolean = false;
	menuColorMode: string = 'light';
	menuColor: string = 'layout-menu-light';
	themeColor: string = 'blue';
	layoutColor: string = 'blue';
	rightPanelClick: boolean;
	rightPanelActive: boolean;
	menuClick: boolean;
	staticMenuActive: boolean = true;
	menuMobileActive: boolean;
	topbarItemClick: boolean;
	topbarMobileMenuClick: boolean;
	topbarMobileMenuActive: boolean;
	sidebarActive: boolean;
	activeTopbarItem: any;
	topbarMenuActive: boolean;
	menuHoverActive: boolean;
	configActive: boolean;
	ripple: boolean = true;
	inputStyle: string = 'outlined';
	
	onLogoClick = new Subject<MouseEvent>();

	constructor(
		private menuService: MenuService,
		private primengConfig: PrimeNGConfig,
	) {

	}

	ngOnInit() {
		this.primengConfig.ripple = this.ripple;
	}

	onLayoutClick() {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		if (!this.rightPanelClick) {
			this.rightPanelActive = false;
		}

		if (!this.menuClick) {
			if (this.isHorizontal()) {
				this.menuService.reset();
			}

			if (this.menuMobileActive) {
				this.menuMobileActive = false;
			}

			this.menuHoverActive = false;
		}

		this.menuClick = false;
		this.topbarItemClick = false;
		this.rightPanelClick = false;
	}

	onTopbarItemClick(event: Event, item: any) {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event.preventDefault();
	}

	onRightPanelButtonClick(event: Event) {
		this.rightPanelClick = true;
		this.rightPanelActive = !this.rightPanelActive;

		event.preventDefault();
	}

	onRightPanelClose(event: Event) {
		this.rightPanelActive = false;
		this.rightPanelClick = false;

		event.preventDefault();
	}

	onRightPanelClick(event: Event) {
		this.rightPanelClick = true;

		event.preventDefault();
	}

	onTopbarMobileMenuButtonClick(event: Event) {
		this.topbarMobileMenuClick = true;
		this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

		event.preventDefault();
	}

	onMenuButtonClick(event: Event) {
		this.menuClick = true;
		this.topbarMenuActive = false;

		if (this.isMobile()) {
			this.menuMobileActive = !this.menuMobileActive;
		}

		event.preventDefault();
	}

	onSidebarClick(event: Event) {
		this.menuClick = true;
	}

	onToggleMenuClick(event: Event) {
		this.staticMenuActive = !this.staticMenuActive;
		event.preventDefault();
	}

	onRippleChange(event: any) {
		this.ripple = event.checked;
		this.primengConfig.ripple = event.checked
	}

	isDesktop() {
		return window.innerWidth > 991;
	}

	isMobile() {
		return window.innerWidth <= 991;
	}

	isHorizontal() {
		return this.horizontalMenu === true;
	}

}
