<span class="p-float-label">
	@if (icon && !focus && !model.value) {
		<i [class]="icon"></i>
	}
	<p-chips [ngModel]="model.value" (ngModelChange)="onChangeHandler($event)" (onBlur)="onInputBlur($event)"
		(onFocus)="onInputFocus($event)" [required]="required" [addOnTab]="true" [addOnBlur]="true"
		[placeholder]="placeholder" [showClear]="true" #controlObj="ngModel" separator="Enter">
	</p-chips>
	<label [for]="name" [ngClass]="{ 'required': required }"
		[ngStyle]="{'margin-left': icon && !focus && !model.value ? '1.5rem' : 'initial'}">
		{{ label }}@if (required && controlObj.invalid && (controlObj.dirty || controlObj.touched)) {
			<ng-container i18n> is required!</ng-container>
		}
	</label>
</span>
