import { Observable, ReplaySubject } from "rxjs";
import { PagedList } from "../modules/table";
import { TableLazyLoadEvent } from "primeng/table";

/**
 * @deprecated
 */
export abstract class ModelProvider<T> {
  abstract getById(id: any): Observable<T>;
};

/**
 * @deprecated
 */
export abstract class PagedListProvider<T> {
  abstract getPagedList(params: TableLazyLoadEvent): Observable<PagedList<T>>;
  abstract get pagedListObserver(): Observable<PagedList<T>>;
}

/**
 * @deprecated
 */
export abstract class ModelSaver<T> {
  abstract save(id: any, dto: any): Observable<T>;
}

/**
 * @deprecated
 */
export abstract class AbstractService<T> implements
  ModelProvider<T>,
  PagedListProvider<T>,
  ModelSaver<T>
{

  protected observables = {
    pagedListObserver: new ReplaySubject<PagedList<T>>(),
  }

  get pagedListObserver() {
    return this.observables.pagedListObserver.asObservable();
  }

  abstract getById(id: any): Observable<T>;
  abstract getPagedList(params: TableLazyLoadEvent): Observable<PagedList<T>>;
  abstract save(id: any, dto: any): Observable<T>;
}
