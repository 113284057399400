import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { SplitButtonModule } from 'primeng/splitbutton';

import { AbstractFilterComponent } from '../abstract';
import { MenuItem } from 'primeng/api';

@Component({
	standalone: true,
	selector: 'br-split-filter',
	templateUrl: './split-filter.component.html',
	imports: [FormsModule, SplitButtonModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SplitFilterComponent),
			multi: true,
		},
	],
})
export class SplitFilterComponent extends AbstractFilterComponent {
	@Input()
	name: string;

	private _items: MenuItem[];
	@Input()
	set items(items: MenuItem[]) {
		if (items?.length) {
			this._items = [...items];
			this.default = this._items.shift();
		}
	}
	get items() {
		return this._items;
	}

	protected default: MenuItem;
}
