import { Directive, Input, OnDestroy } from '@angular/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../services';

export interface TableStateConfig {
	skipFilters?: boolean,
	resetPage?: boolean,
}

@Directive({
	standalone: true,
	selector: "p-table[stateStorage=service], p-table[serviceStorage]",
})
export class TableServiceStorageDirective implements OnDestroy {

	private _restored: boolean;
	private _settings: any;
	private subscriptions = new Subscription();

	@Input()
	stateConfig: TableStateConfig;

	constructor(private table: Table, private sSvc: SettingsService) {
		if (!sSvc) { 
			return;
		}
		this.subscriptions.add(
			sSvc.onChanges$.subscribe(settings => this.restoreTableState(settings))
		);

		this.defineServiceStorage();
		this.fixColumnOrderProcessing();
	}

	// fix columns order - we have PR fired in primeng repo
	private fixColumnOrderProcessing() {
		Object.defineProperty(this.table, "saveColumnOrder", {
			value: (state: any) => {
				if (this.table.columns) {
					let columnOrder: string[] = [];
					this.table.columns.map((column: any) => {
						columnOrder.push(column.key || column.field)
					});
					state.columnOrder = columnOrder;
				}
			}
		});
	}

	private defineServiceStorage() {
		const serviceStorage = {
			setItem: (key: string, value: string): void => {
				if (this.sSvc.context != key) {
					throw("Table.stateKey must be equal to SettingsService.context")
				}
				// do not save before curred state is read
				if (this._restored) {
					const data = JSON.parse(value);
					this.sSvc.set(this.applyConfig(data));
				}
			},
			getItem: (key: string): string => {
				if (this.sSvc.context != key) {
					throw("Table.stateKey must be equal to SettingsService.context")
				}
				// TODO: to be checked why and how this is happening
				if (this._settings && !this._settings.rows) {
					this._settings.rows = this.table.rows;
				}
				const data = JSON.stringify(this._settings);
				return data;
			},
			removeItem(key: string) {
				if (this.sSvc.context != key) {
					throw("Table.stateKey must be equal to SettingsService.context")
				}
				this.sSvc.set(null);
			}
		} as Storage;
		
		// override getStorage()
		// so we can use custom storage mechanism
		Object.defineProperty(this.table, "getStorage", {
			value: () => serviceStorage,
		});
	}

	private restoreTableState(settings: any) {
		settings = this.applyConfig(settings);
		this._settings = settings;
		this.table.restoreState();
		this.table.restoreColumnWidths();
		this.table.restoreColumnOrder();
		if (settings && settings.sortField && this.table.sortMode == 'single') {
			let sortMeta = {
				field: settings.sortField,
				order: settings.sortOrder || 1
			};
			this.table.tableService.onSort(sortMeta);
		}
		if (!settings?.filters) {
			// as initial lazy loading is disabled until state is restored
			// we have to initiate data loading by hand
			this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
		}
		this._restored = true;
	}

	private applyConfig(data: any) {
		if (data) {
			delete data.selection; // don't store selection
			delete data.expandedRowKeys; // don't store expanded rows
			if (this.stateConfig) {
				if (this.stateConfig.skipFilters) {
					// if we have saved filters before this option
					delete data.filters;
				}
				// current page should not be saved
				if (this.stateConfig.resetPage) {
					data.first = 0;
				}
				// other options to be added
			}
		}
		return data;
	}
	
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
