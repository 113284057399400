<div class="subpattern-dialog" [style]="{minWidth: '200px', maxHeight: '400px'}">
	<p-table [value]="subPatternsDialog" class="subpattern-table">
		<ng-template pTemplate="body" let-subpattern>
			<tr class="subpattern-row" (click)="onSubpatternSelected(subpattern)">
				<td>{{ subpattern.label }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>

