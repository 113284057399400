import { ReplaySubject, Subject } from "rxjs";
import { ImageDto } from "@biorec/common";

export interface ImageBrokerPayload {
	subType?: string,
	defaultGroup?: number,
	model?: any,
	maxSize?: number,
}

/**
	Handles edit mode of image (selection or upload)
	Provides communication between Image component, ImageUpload component and ImagesGroupsTree component.
 */
export class ImagesBrokerService {

	private active: boolean = false;
	private _onActivate$ = new Subject<ImageBrokerPayload>();
	private _onDeactivate$ = new Subject<ImageDto>();
	private _imageProvider = new ReplaySubject<ImageDto>(1);

	readonly onActivate = this._onActivate$.asObservable();//pipe(share());
	readonly onDeactivate = this._onDeactivate$.asObservable();//pipe(share());
	readonly onImage = this._imageProvider.asObservable();


	emitActivate(payload: ImageBrokerPayload) {
		if (this.active) {
			return;
		}
		this.active = true;
		this._onActivate$.next(payload);
	}

	emitDeactivate(payload: ImageDto) {
		if (!this.active) {
			return;
		}
		this.active = false;
		this._onDeactivate$.next(payload);
	}

	isNativeImage(subType: string) {
		return !['EXAMS', 'LOGOS'].includes((subType || '').toUpperCase());
	}

	emitNativeImage(dto: ImageDto) {
		this._imageProvider.next(dto);
	}

	emitImageUpload(payload: ImageBrokerPayload) {
		this._onActivate$.next(payload);
	}
}
