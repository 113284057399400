import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: 'stopSanitize',
	standalone: true,
})

export class StopSanitize implements PipeTransform {
	constructor(private sanitizer: DomSanitizer){};

	transform(value: any, type?: String) {
		if (type == "html") {
			return this.sanitizer.bypassSecurityTrustHtml(value);
		} else if (type == "url") {
			return this.sanitizer.bypassSecurityTrustUrl(value);
		} else if (type == "style") {
			return this.sanitizer.bypassSecurityTrustStyle(value);
		} else if (type == "script") {
			return this.sanitizer.bypassSecurityTrustScript(value);
		} else if (type == "resourceUrl") {
			return this.sanitizer.bypassSecurityTrustResourceUrl(value);
		} else {
			throw new Error("Method not implemented.");
		}
	}
}
