@if (readonly || disabled) {
  <input pInputText
    [disabled]="true"
    [value]="value | dateFormat : format"
    />
}
@else {
  <p-calendar
    [ngModel]="value | toJsDate"
    (ngModelChange)="onValueChange($event)"
    [minDate]="minDate | toJsDate"
    [maxDate]="maxDate | toJsDate"
    dateFormat="dd.mm.yy"
    [readonlyInput]="true"
    [showTime]="showTime"
    [showIcon]="false"
    [showClear]="true"
    [showButtonBar]="true"
    appendTo="body"
    [required]="required"
    [style]="style"
    [baseZIndex]="10000"
    [styleClass]="styleClass"
  ></p-calendar>
}
