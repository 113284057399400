import { Inject, Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";
import { SettingsDocument, SettingsMutation } from "../graphql/documents";
import { SETTINGS_CONTEXT } from "../shared";
import { GraphQLClient } from "./graphql-client";

@Injectable()
export class SettingsService {

	private _onChanges: ReplaySubject<any> = new ReplaySubject(1);
	readonly onChanges$ = this._onChanges.pipe(share());
	
	constructor(private client: GraphQLClient, @Inject(SETTINGS_CONTEXT) public context: string) {
		if (context != null) {
			this.load();
		}
	}

	load(silent?: false) {
		const o = this.client.request(SettingsDocument, { context: this.context })
			.pipe(map((result: any) => result.Settings?.content), share());
		o.subscribe(settings => !silent ? this._onChanges.next(settings) : null);
		return o;
	} 

	set(settings: any, silent?: boolean) {
		const o = this.client.request(SettingsMutation, { dto: { context: this.context, content: settings } })
			.pipe(map((response: any) => response.result), share());
		o.subscribe(result => (result && !silent) ? this._onChanges.next(settings) : null);
		return o;
	}
}
