import { Pipe } from '@angular/core';

@Pipe({
	name: 'toWords',
	standalone: true,
})
export class ToWordsPipe {

	private def: { [key: number]: string } = {
		0: 'ΜΗΔΕΝ',
		1: 'ΕΝΑ',
		2: 'ΔΥΟ',
		3: 'ΤΡΙΑ',
		4: 'ΤΕΣΣΕΡΑ',
		5: 'ΠΕΝΤΕ',
		6: 'ΕΞΙ',
		7: 'ΕΠΤΑ',
		8: 'ΟΚΤΩ',
		9: 'ΕΝΝΕΑ',
		10: 'ΔΕΚΑ',
		11: 'ΕΝΤΕΚΑ',
		12: 'ΔΩΔΕΚΑ',
		13: 'ΔΕΚΑΤΡΙΑ',
		14: 'ΔΕΚΑΤΕΣΣΕΡΑ',
		15: 'ΔΕΚΑΠΕΝΤΕ',
		16: 'ΔΕΚΑΕΞΙ',
		17: 'ΔΕΚΑΕΠΤΑ',
		18: 'ΔΕΚΑΟΚΤΩ',
		19: 'ΔΕΚΑΕΝΝΕΑ',
		20: 'ΕΙΚΟΣΙ',
		30: 'ΤΡΙΑΝΤΑ',
		40: 'ΣΑΡΑΝΤΑ',
		50: 'ΠΕΝΗΝΤΑ',
		60: 'ΕΞΗΝΤΑ',
		70: 'ΕΒΔΟΜΗΝΤΑ',
		80: 'ΟΓΔΟΝΤΑ',
		90: 'ΕΝΕΝΗΝΤΑ',
		100: 'ΕΚΑΤΟΝ',
		200: 'ΔΙΑΚΟΣΙΑ',
		300: 'ΤΡΙΑΚΟΣΙΑ',
		400: 'ΤΕΤΡΑΚΟΣΙΑ',
		500: 'ΠΕΝΤΑΚΟΣΙΑ',
		600: 'ΕΞΑΚΟΣΙΑ',
		700: 'ΕΠΤΑΚΟΣΙΑ',
		800: 'ΟΚΤΑΚΟΣΙΑ',
		900: 'ΕΝΝΙΑΚΟΣΙΑ',
		1000: 'ΧΙΛΙΑ',
		2000: 'ΔΥΟ ΧΙΛΙΑΔΕΣ',
		3000: 'ΤΡΕΙΣ ΧΙΛΙΑΔΕΣ',
		4000: 'ΤΕΣΣΕΡΙΣ ΧΙΛΙΑΔΕΣ',
		5000: 'ΠΕΝΤΕ ΧΙΛΙΑΔΕΣ',
		6000: 'ΕΞΙ ΧΙΛΙΑΔΕΣ',
		7000: 'ΕΠΤΑ ΧΙΛΙΑΔΕΣ',
		8000: 'ΟΚΤΩ ΧΙΛΙΑΔΕΣ',
		9000: 'ΕΝΝΕΑ ΧΙΛΙΑΔΕΣ',
	}
	private cents = 'λεπτά';
	private euro = 'Ευρώ';
	private and = 'και';
	public transform(value: number, r = false) {
		if (!value || isNaN(value) || value >= 10000 || value == 0 || value.toString() == '0') {
			return '';
		}
		let p = value.toString().split('.');
		let result: Array<string> = [];
		let v;
		const p0 = parseInt(p[0]);
		switch (true) {
			case p0 < 21:
				result.push(this.def[p0]);
				break;
			case p0 < 100:
				v = Math.floor(p0 / 10) * 10;
				result.push(this.def[v]);
				result.push(this.transform(p0 % 10, true));
				break;
			case p0 < 1000:
				v = Math.floor(p0 / 100) * 100;
				result.push(this.def[v]);
				result.push(this.transform(p0 % 100, true));
				break;
			default:
				v = Math.floor(p0 / 1000) * 1000;
				result.push(this.def[v]);
				result.push(this.transform(p0 % 1000, true));
		}
		if (!r) {
			result.push(this.euro);
			if (p[1] && p[1].toString().length > 0) {
				result.push(this.and);
				let p1 = parseInt(p[1]);
				if (p1 > 99) {
					p1 = parseInt(p[1].replace(/^(\d\d).*$/, '$1'));
				} else if (p[1].length == 1) {
					p1 *= 10;
				}
				result.push(this.transform(p1, true));
				result.push(this.cents);
			}
		}
		return result.join(' ');
	}
}
