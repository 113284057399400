import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
	ViewChild,
	OnChanges,
	SimpleChanges,
	inject,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {
	Table,
	TableLazyLoadEvent,
	TableModule,
	TableRowSelectEvent,
	TableRowUnSelectEvent,
} from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { ResolveFieldPipe } from '../../common';
import { SETTINGS_CONTEXT } from '../../../shared';
import { FiltersObject } from '../../filters';
import { TableColumnSelectorComponent } from '../columns-selector/table-columns-selector.component';
import { ColumnsSetupDirective } from '../columns-setup.directive';
import { ColumnDefinition, PagedList } from '../common';
import {
	TableServiceStorageDirective,
	TableStateConfig,
} from '../table-service-storage.directive';
import { ObjectUtils } from "primeng/utils";
import { AadeSyncStatus } from '@biorec/admin/domain';
import { StopSanitize } from '../../common/pipes/stopSanitize.pipe';

@Component({
	standalone: true,
	selector: 'br-default-table',
	templateUrl: './default-table.component.html',
	imports: [
		NgStyle,
		NgClass,
		AsyncPipe,
		NgTemplateOutlet,
		TableModule,
		TooltipModule,
		OverlayPanelModule,
		ButtonModule,
		TableServiceStorageDirective,
		ColumnsSetupDirective,
		TableColumnSelectorComponent,
		ResolveFieldPipe,
		FormsModule,
		StopSanitize
	],
})
export class DefaultTableComponent implements OnInit, OnChanges {

	@Input()
	rpp: number = 25;

	@Input()
	rppOptions: Array<number> = [5, 10, 25, 50, 100];

	@Input()
	selectionMode: any = 'single';

	@Input()
	resizable: boolean = true;

	@Input()
	reorderable: boolean = true;

	@Input()
	caption: string;

	@Input({ required: true })
	columns: ColumnDefinition[];

	@Input({ required: true })
	$data: Observable<PagedList<any>>;

	@Input()
	stateConfig: TableStateConfig = {};

	@Input()
	columnsSelector: boolean = true;

	@Input()
	selection: any;

	@Input()
	sendToAade: (event: any, item: any) => void;


	@Output()
	onLazyLoad = new EventEmitter<any>(); //<TableLazyLoadEvent>();

	@Output()
	onRowSelect = new EventEmitter<any>(); //<TableRowSelectEvent>();

	@Output()
	onRowUnselect = new EventEmitter<TableRowUnSelectEvent>();

	@ContentChild('extraFirstCol') extraFirstCol: TemplateRef<any>;

	@ContentChild('extraLastCol') extraLastCol: TemplateRef<any>;

	@Output()
	filtersChange = new EventEmitter<FiltersObject>();

	@ViewChild(Table) protected table: Table;

	@Input()
	filters: FiltersObject;

	protected aadeStatus = AadeSyncStatus;

	readonly fieldResolver = ObjectUtils.resolveFieldData.bind(ObjectUtils);

	protected settingsContext: string = inject(SETTINGS_CONTEXT);

	constructor() {}

	get lazyLoadMetaData(): TableLazyLoadEvent {
		return this.table.createLazyLoadMetadata();
	}

	get tableValue(): any {
		return this.table.value;
	}

	ngOnInit(): void {
		if (['single', 'multiple', 'none'].includes(this.selectionMode)) {
			if (this.selectionMode == 'none') {
				this.selectionMode = null;
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filters) {
			if (!changes.filters.firstChange) {
				setTimeout(() => (this.table ? this.table._filter() : null));
			}
		}
	}

	reload() {
		this.selection = null;
		this.onLazyLoad.emit(this.lazyLoadMetaData);
	}

	protected clearSort() {
		this.table.sortField = null;
		this.table.sortOrder = this.table.defaultSortOrder;
		this.table.multiSortMeta = null;
		this.table.tableService.onSort(null);
		this.reload();
	}

	protected onStateRestore($event: any) {
		if (!this.stateConfig?.skipFilters) {
			this.filters = $event.filters;
			this.filtersChange.emit(this.filters);
		}
	}

	protected loadData(event: TableLazyLoadEvent) {
		this.onLazyLoad.emit(event);
	}

	protected rowSelected(event: TableRowSelectEvent) {
		this.onRowSelect.emit(event);
	}

	protected rowUnselected(event: TableRowUnSelectEvent) {
		this.onRowUnselect.emit(event);
	}

}
