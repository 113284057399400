import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { skipWhile, share } from 'rxjs/operators';
import { SettingsService } from '../../services';

@Injectable()
export class TabsService {

	private openedTabs$ = new BehaviorSubject<MenuItem[]>(undefined);
	private selectedTab$ = new BehaviorSubject<number>(undefined);

	readonly openedTabs = this.openedTabs$.pipe(skipWhile(val => !val), share());
	readonly selectedTab = this.selectedTab$.pipe(skipWhile(val => val !== undefined), share());

	constructor(
		private storageSvc: SettingsService,
	) {
		storageSvc.onChanges$.subscribe((items: MenuItem[]) => {
			if (items?.length) {
				// register for tabs changes (on load or after successful save)
				this.openedTabs$.next(items) ;
			}
		});
	}

	openTab(item: MenuItem) {
		const tab = this.getTab(item);
		if (item?.id && !tab) {
			// create it and open it
			this.createTab(item);
		} else if (item?.label && tab.label != item.label) {
			// update name if it was changed after tab was created
			// for example: edit by other user
			tab.label = item.label;
			// re-emit items to be updated on the component
			this.openedTabs$.next(this.openedTabs$.getValue());
			this.storageSvc.set(this.openedTabs$.getValue(), true);
		}
		setTimeout(() => this.selectedTab$.next(this.getTabIndex(item)));
	}


	closeTabByIndex(idx: number) {
		let items = this.openedTabs$.getValue();
		items.splice(idx, 1);
		this.openedTabs$.next(items);
		this.storageSvc.set(items, true);

		// if closed tab is current selected tab or no more opened tabs notify for this
		if (this.selectedTab$.getValue() == idx || !items.length) {
			this.selectedTab$.next(null);
		}
	}

	closeAllTabs() {
		this.storageSvc.set([], true);
		this.openedTabs$.next([]);
		this.selectedTab$.next(null);
	}

	getTabByIndex(idx: number) {
		return this.openedTabs$.getValue()[idx];
	}

	private createTab(item: MenuItem) {
		let items = this.openedTabs$.getValue() || [];
		items.unshift(item);
		this.storageSvc.set(items, true);
		this.openedTabs$.next(items);
	}

	private getTabIndex(item: MenuItem) {
		if (!item) {
			return -1;
		}
		return this.openedTabs$.getValue().findIndex(this.predicateGeneratorFn(item));
	}

	private getTab(item: MenuItem) {
		const items = this.openedTabs$.getValue();
		if (items) {
			return items.find(this.predicateGeneratorFn(item));
		}
		return null;
	}

	/**
	 * Create a function used for array processing that determines if
	 * provided item is equal to an item from array.
	 */
	private predicateGeneratorFn(item: MenuItem): (value: MenuItem, index: number, array: MenuItem[]) => boolean {
		return i => item && i.id == item.id;
	}
}
