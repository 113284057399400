import { SkipSelf, Optional, Injector, Type } from '@angular/core';
import { SettingsService } from '../services';
import { SETTINGS_CONTEXT } from './tokens';

export function provideIfMissing<T>(providerToken: Type<T>) {
	return {
		provide: providerToken,
		useFactory: (parent: Injector, existing: T) => {
			if (!existing) {
				existing = Injector.create({ providers: [{ provide: providerToken }], parent}).get(providerToken)
			}
			return existing;
		},
		deps: [Injector, [new SkipSelf(), new Optional(), providerToken]],
	};
}

export function getContextProvider(context: string, skipParent?: boolean) {
	return {
		provide: SETTINGS_CONTEXT,
		useFactory: (parent: string) =>
			parent && !skipParent
				? `${parent}_${context}`
				: context,
		deps: [[new SkipSelf(), new Optional(), SETTINGS_CONTEXT]],
	};
}

export function getSettingsServiceProviders(
	context: string,
	skipParent?: boolean
) {
	return [getContextProvider(context, skipParent), SettingsService];
}
