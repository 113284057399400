<ng-container>
  @if ((!item.routerLink || item.items) && item.visible !== false) {
    <a [attr.href]="item.url" (click)="itemClick($event)" (mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)"
      [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class">
      <span class="menuitem-text">{{item.label}}</span>
      @if (item.items) {
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
      }
      <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    </a>
  }
  @if ((item.routerLink && !item.items) && item.visible !== false) {
    <a (click)="itemClick($event)" (mouseenter)="onMouseEnter()"
      [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink" [ngClass]="item.class"
      [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
      <span class="menuitem-text">{{item.label}}</span>
      @if (item.items) {
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
      }
      <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    </a>
  }
  @if ((item.items && active) && item.visible !== false) {
    <ul [@children]="(appMain.isHorizontal() && root) ? (active ? 'visible' : 'hidden') :
      (active ? 'visibleAnimated' : 'hiddenAnimated')">
      @for (child of item.items; track child; let i = $index) {
        <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
      }
    </ul>
  }
</ng-container>