import { Inject, Injectable, Optional } from "@angular/core";
import { fromEvent, ReplaySubject, Subscription } from "rxjs";
import { SettingsService } from "./settings.service";
import {  SizeUtils } from "../shared";
import { ColumnDefinition, ColumnsDefinitions } from "../shared/columns-definistions";
import { COLUMNS_DEFINITIONS } from "../modules";

@Injectable()
export class ColumnsSetupService {

	public columnsChanged: ReplaySubject<ColumnDefinition[]> = new ReplaySubject(1);
	private resizeTimer: NodeJS.Timeout;

	constructor(
		@Inject(COLUMNS_DEFINITIONS) private definitions: ColumnsDefinitions,
		@Optional() private sSvc: SettingsService,
	) {
		fromEvent(window, 'resize').subscribe(this.onWindowResize.bind(this));

		this.initialize();
	}

	private async initialize() {
		await this.applyUserConfig();
		this.processColumns();
	}

	private async applyUserConfig() {
		if (this.sSvc) {
			//const settings = await this.sSvc.getSettings();
			// apply any configuration for the columns
			// that table could not process alone
			this.definitions.columns.forEach(col => {
				col.visible = true;
			});
		}
	}

	onWindowResize(e: Event) {
		clearTimeout(this.resizeTimer);
		this.resizeTimer = setTimeout(this.processColumns.bind(this), 100);
	}

	private processColumns() {
		// deep copy columns
		const cols: ColumnDefinition[] = [];

		this.definitions.columns.forEach(col => {
			cols.push({ ...col });
		})

		for (let size of Object.values(SizeUtils)) {
			if (size.check() && this.definitions.responsive[size.symbol]) {
				cols.forEach(col => {
					if (this.definitions.responsive[size.symbol].indexOf(col.key) == -1) {
						col.visible = false;
					}
				});
				break;
			}
		}
		this.columnsChanged.next(cols);
	}

	registerOnChange(fn: any): Subscription {
		return this.columnsChanged.subscribe(fn);
	}
}
