import { Injectable, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { HttpInterceptor as InterceptorInterface, HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Message, MessageService } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class HttpInterceptor implements InterceptorInterface {

	private activeRequests: number = 0;
	protected loading: HTMLElement | null;

	public constructor(
		private location: Location,
		private msgSvc: MessageService,
		private kcs: KeycloakService,
		@Inject(DOCUMENT) doc: Document,
		) {
			
		this.loading = doc.getElementById('loading');
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let params: {
			headers?: HttpHeaders;
			reportProgress?: boolean;
			params?: HttpParams;
			responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
			withCredentials?: boolean;
			body?: any;
			method?: string;
			url?: string;
			setHeaders?: {
				[name: string]: string | string[];
			};
			setParams?: {
				[param: string]: string;
			};
		} = {};

		// TODO: set Authentication token
		
		this.startRequest();
		return next.handle(request.clone(params)).pipe(
			tap(response => {
				// response code 2xx
				if (response instanceof HttpResponse) {
					this.finishRequest();
					if (response.body.errors) {
						// graphql backend returns errors with response code 200
						this.processErrorResponse(new HttpErrorResponse({
							error: response.body.errors,
							headers: response.headers,
							status: response.status,
							statusText: response.statusText,
							url: response.url
						}));
					}
				}
			},response => {
					this.finishRequest();
					this.processErrorResponse(response);// response code 4xx or 5xx
			})
		);
	}
	
	protected processErrorResponse(response: HttpErrorResponse) {
		if (!(response instanceof HttpErrorResponse)) {
			throw ("I don't know how to deal with this error response type: " + (<any>response).constructor.name);
		}
		
		if (response.status > 400 && response.status < 404 && !this.location.path().match(/\/login$/)) {
			
			// TODO: invalidate user's token
			this.kcs.logout();
			// IMPORTANT !!! angular's router doesn't work here
			// DO NOT CHANGE THIS LINE !!!
			this.location.go('/login');
			return;
		} else if (response.status == 417) { // correct status should be used here
			/**
			 * This is just a snipped!
			 * Real processing could be different

			this.msgSvc.add({
				severity: 'error',
				summary: 'Error!',
				detail: response.error.join("<br/>"),
			});
			*/
			return;
		} else {
			const messages: Message[] = [];
			if (response.error && !(response.error instanceof ProgressEvent)) {
				if (Array.isArray(response.error)) {
					for (let e of response.error) {
						messages.push(this.processError(e));
					}
				} else {
					messages.push(this.processError(response.error));
				}
			} else {
				messages.push({
					severity: 'error',
					summary: response.statusText,
					detail: response.message,
				});
			}
			this.msgSvc.addAll(messages);
			// rethrow error so we can process internally too
			throw response;
		}
	}
	
	protected processError(e: any): Message {
		const msg: Message = {
			severity: 'error',
			summary: 'Error',
			detail: 'Unknown error occured!',
		};
		if (e.message) {
			// graphql case
			msg.detail = e.message;
		} else {
			// TODO: extend with more cases
		}
		return msg;
	}

	protected startRequest() {
		this.activeRequests++;
		if (this.loading) {
			this.loading.style.display = '';
		}
	}

	protected finishRequest() {
		this.activeRequests--;
		if (!this.activeRequests && this.loading) {
			this.loading.style.display = 'none';
		}
	}
}
