import { Subscription } from "rxjs";

import { FiltersObject } from "../common";
import { FiltersBrokerService } from '../filters-broker.service';
import { FilterMetadata } from "primeng/api";
import { Component, OnDestroy, Optional } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

/**
  * This class should be used as base for all filters containers in lists and reports
  * It supports two way of managing filters values - via service, or via [(ngModel)]
  * In general just one should be used, depending on the situation
 */
 @Component({
	template: ''
})
export abstract class AbstractFilterContainerComponent implements ControlValueAccessor, OnDestroy {

	protected model: FiltersObject = {};
	protected subscriptions = new Subscription();
	private isInternalChange: boolean;
	
	onModelTouched: Function = (_:any) => {};
	onModelChange: Function = (_:any) => {};
	
	constructor(@Optional() protected svc: FiltersBrokerService = null) {
		if (this.svc) {
			this.subscriptions.add(
				this.svc.onChanges$.subscribe(filters => this.onFiltersChanged(filters))
			);
		}
	}

	setDisabledState?(isDisabled: boolean): void {
		throw new Error("Method not implemented.");
	}
	registerOnTouched(fn: any): void {
		this.onModelTouched = fn;
	}
	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}
	writeValue(filters: FiltersObject): void {
		this.onFiltersChanged(filters);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected onFiltersChanged(filters: FiltersObject) {
		if (this.isInternalChange) {
			this.isInternalChange = false;
			return;
		}
		this.model = filters;
	}
	
	protected onChangeHandler(data: FilterMetadata, name: string) {
		// just if it's not yet propagated
		if (data == null) {
			delete this.model[name];
		} else {
			this.model[name] = data;
		}
		const changedValue = { ...this.model };
		if (this.svc) {
			this.isInternalChange = true;
			this.svc.emitChanges(changedValue);
		}
		this.onModelChange(changedValue);
	}

}
