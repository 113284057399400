import { NgClass, NgStyle } from "@angular/common";
import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { InputTextModule } from "primeng/inputtext";
import { DateFormat, ToJsDatePipe, AbstractValueAccessor } from "../common";
import { dateFormat } from '../../shared';

@Component({
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    DateFormat,
    FormsModule,
    ToJsDatePipe,
    CalendarModule,
    InputTextModule
],
  host: {
    class: 'p-inputwrapper'
  },
  selector: 'br-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent extends AbstractValueAccessor<string> implements OnInit {

  @Input()
  minDate: string | Date;

  @Input()
  maxDate: string | Date;

  @Input()
  showTime: boolean;

  //@Input()
  protected format: string = '';

  @Input()
  inputStyle: Object;

  constructor(private el: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (!this.format) {
      this.format = this.showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
    }
  }

  override writeValue(value: string): void {
    super.writeValue(value);
    this.setFilledState();
  }

  protected override onValueChange(value: Date): void {
    this.value = value;
    this.onChange(dateFormat(value, this.showTime ? "yyyy-MM-dd'T'HH:mm:ss" : 'yyyy-MM-dd'));
    this.setFilledState()
  }

  private setFilledState() {
    if (this.value) {
      this.el.nativeElement.classList.add('p-inputwrapper-filled');
    } else {
      this.el.nativeElement.classList.remove('p-inputwrapper-filled');
    }
  }
}
