<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{'layout-horizontal': horizontalMenu,
  'layout-wrapper-static': staticMenuActive,
  'layout-rightpanel-active': rightPanelActive,
  'layout-topbar-mobile-active': topbarMobileMenuActive,
  'layout-sidebar-mobile-active': menuMobileActive,
  'p-input-filled': inputStyle === 'filled',
  'p-ripple-disabled': !ripple}" [class]="menuColor">
	<app-menu></app-menu>
	<div class="layout-main">
		<app-rightpanel></app-rightpanel>
		<router-outlet></router-outlet>
		<app-footer></app-footer>
		<app-config></app-config>
		<div class="layout-main-mask"></div>
		@if (configActive) {
			<div class="layout-config-mask" [@mask-anim]="'visible'"></div>
		}
	</div>
</div>
