import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { ChipsModule } from 'primeng/chips';

import { MatchMode } from '../../../domain';
import { AbstractFilterComponent } from '../abstract';
import { NgClass, NgStyle } from '@angular/common';

@Component({
	standalone: true,
	selector: 'br-global-search-filter',
	templateUrl: './global-search-filter.component.html',
	styles: [
		`
			::ng-deep .p-chips .p-chips-multiple-container {
				flex-wrap: nowrap;
			}
			i.mdi {
				position: absolute;
				z-index: 1;
				margin: 0.5rem;

				&:before {
					font-size: 1.7rem;
				}
			}
			label {
				font-size: 1.2rem;
				white-space: nowrap;
			}
		`,
	],
	imports: [NgClass, NgStyle, FormsModule, ChipsModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GlobalSearchFilterComponent),
			multi: true,
		},
	],
})
export class GlobalSearchFilterComponent extends AbstractFilterComponent {
	@Input()
	label: string = $localize`Search`;

	@Input()
	placeholder: string;

	@Input()
	icon: string;

	@Input()
	required: boolean = false;

	@Input()
	name: string;

	protected override matchMode: MatchMode = MatchMode.Contains;
}
