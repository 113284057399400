@if (!styleClass) {
	<div class="py-4">&nbsp;</div>
}
<div [ngClass]="styleClass || 'p-dialog-footer flex justify-content-around fixed bottom-0 left-0 right-0'">
	<button type="button" (click)="form.onCancel()" pButton class="p-button-secondary m-1" icon="mdi mdi-cancel"
		label="Cancel" i18n-label>
	</button>
	<button type="button" (click)="form.onReset()" pButton class="p-button-warning m-1" icon="mdi mdi-restore"
		label="Reset" i18n-label>
	</button>
	<button type="button" pButton (click)="form.onSubmit()" class="p-button-primary m-1" icon="mdi mdi-content-save"
		label="Save" i18n-label [disabled]="form.isFormPristine">
	</button>
	<button type="button" pButton (click)="form.onSubmit(true)" class="p-button-primary m-1" icon="mdi mdi-content-save"
		label="Save & Close" i18n-label [disabled]="form.isFormPristine">
	</button>
</div>
