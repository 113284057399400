import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, share } from "rxjs/operators";

import { GraphQLClient } from "./";
import { prepareDownload } from "../shared";
import { GeneratePdfDocument } from "../graphql/documents";

@Injectable()
export class PdfServiceGql {

	constructor(private client: GraphQLClient) {
	}
	
	generatePdf(content: string, fileName?: string): Observable<any> {
		const o = this.client.request(GeneratePdfDocument, { content })
		.pipe(map((result: any) => <any>result.GeneratePdf ), share());
		
		o.subscribe(result => {
			const { content, contentType, filename } = result;
			prepareDownload(content, contentType, fileName || filename);
		});
		return o;
	}
}