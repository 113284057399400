import gql from 'graphql-tag';

export const PatternsListDocument = gql`
query PatternsList ($params: BaseParamsInput = {}) {
	PatternsList (params: $params) {
		id
		name
		content
		type
		model
	}
}
`;

export const SendMailDocument = gql`
mutation SendMail ($data: SendDataInput) {
	SendMail (data: $data) {
		result
	}
}
`;

export const GeneratePdfDocument = gql`
query GeneratePdf($content: String) {
	GeneratePdf(content: $content) {
		content
		contentType
		filename
	}
}
`;

export const SettingsDocument = gql`
query Settings($context: String) {
	Settings(context: $context) {
		content
	}
}
`;

export const SettingsMutation = gql`
mutation Settings($dto: SettingsDtoInput) {
	Settings(dto: $dto) {
		result
		message
	}
}
`;

export const PatternContentDocument = gql`
query PatternContent($id: Int) {
	Pattern(id: $id) {
		id
		content
		type
		name
		model
	}
}
`;

export const PlaceholderValuesDocument = gql`
query PlaceholderValues($id: String) {
	Placeholder(id: $id) {
		id
		values {
			label
			value
		}
	}
}
`;

export const ImageContentDocument = gql`
query ImageContent($id: Int) {
	ImageContent(id: $id) {
		id
		full
	}
}
`;
