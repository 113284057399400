import { Component, DoCheck, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { Observable, Subscription } from "rxjs";
import { DefaultDialogConfig } from "./default-dialog-config";

@Component({
	standalone: true,
	selector: 'br-default-dialog',
	template: '',
	imports: [
		DynamicDialogModule
	],
	providers: [ DialogService ],
})
export class DefaultDialogComponent<T> implements OnInit, OnDestroy, DoCheck {

	private ref: DynamicDialogRef;
	private subscriptions = new Subscription();
	private childCreated: boolean;

	@Output()
	componentCreated = new EventEmitter<T>();

	constructor(
		private svc: DialogService,
		private conf: DefaultDialogConfig<T>,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.ref = this.svc.open(this.conf.getComponent(), this.conf);
	}

	getActivatedRoute() {
		return this.route
	}

	getDialog() {
		return this.svc.dialogComponentRefMap.get(this.ref).instance;
	}

	getComponent(): T {
		return this.getDialog().componentRef?.instance;
	}

	ngOnInit(): void {
		if (this.conf.closeHandler) {
			this.getDialog().hide = this.canClose.bind(this);
		}
		this.subscriptions.add (
			this.ref.onClose.subscribe(this.close.bind(this)),
		);
	}

	canClose() {
		const result = this.conf.closeHandler(this);
		if (result instanceof Observable) {
			this.subscriptions.add (
				(<Observable<boolean>> result).subscribe(r => r && this.ref.close())
			);
		} else if (result instanceof Promise) {
			(<Promise<boolean>> result).then(r => r && this.ref.close());
		} else {
			result && this.ref.close();
		}
	}

	ngDoCheck(): void {
		if (this.conf.onChildCreated && !this.childCreated && this.getComponent()) {
			this.childCreated = true;
			this.conf.onChildCreated(this);
			this.componentCreated.emit(this.getComponent());
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	close(path?: string[]) {
		this.getDialog().close();
		if (path?.length) {
			this.router.navigate(path, { relativeTo: this.route });
		} else if (this.conf.navigateOnClose) {
			this.router.navigate(this.conf.navigateOnClose, { relativeTo: this.route });
		}
	}
}
