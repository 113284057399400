import { Observable } from "rxjs";
import { Pattern } from "../../../domain";
import { Injectable } from "@angular/core";
import { PatternContentDocument, PatternsListDocument } from '../../../graphql/documents';
import { GraphQLClient } from "../../../services";
import { map, share } from "rxjs/operators";
import { LazyLoadEvent } from "primeng/api";
import { adaptParams } from "@biorec/common";

@Injectable()
export class PatternsServiceGql {

	constructor(private client: GraphQLClient) {
	}

	get(patternId: number): Observable<Pattern> {
		const o = this.client.request(PatternContentDocument, { id: patternId })
			.pipe(map(result => (<any>result).Pattern as Pattern), share());
		return o;
	}

	getAll(params: LazyLoadEvent): Observable<Array<Pattern>> {
		const o = this.client.request(PatternsListDocument, { params: adaptParams(params) })
			.pipe(map(result => (<any>result).PatternsList as Pattern[]), share());
		return o;
	}
}
