<div class="p-float-label field">
	@if (clear) {
		<div class="absolute right-0">
			<p-button id="btnClear" icon="pi pi-times" styleClass="z-2" pTooltip="Clear" i18n-pTooltip
				tooltipPosition="left" (click)="addressCleared($event)"></p-button>
		</div>
	}
	<p-autoComplete [required]=required [disabled]="disabled" [ngModel]="value?.fullAddress" [delay]="1000"
		[minLength]="5" [suggestions]="mapSuggestions" field="fullAddress" (completeMethod)="mapSearch($event)"
		(onSelect)="addressSelected($event.value)" (onClear)="addressCleared($event)" (onBlur)="markAsTouched()"
		[forceSelection]="true" [showEmptyMessage]="true" [ngClass]="{ 'ng-touched': ngControl.touched }"
		emptyMessage="Nothing found!" i18n-emptyMessage>
		<ng-template let-suggestion pTemplate="item">{{ suggestion.formatted_address }}</ng-template>
	</p-autoComplete>
	<label [ngClass]="{ 'required': required }" for="fullAddress">
		<ng-container i18n>Address</ng-container>
		@if (required && ngControl.touched && ngControl.invalid) {
			<ng-container i18n> is required!</ng-container>
		}
	</label>
</div>
<div class="map-layout">
	<google-map [options]="mapOptions" [style]="{'width':'100%','height':'100%'}">
		<map-marker [position]="marker.getPosition()" [label]="marker.getLabel()" [title]="marker.getTitle()">
		</map-marker>
	</google-map>
</div>
<!-- https://timdeschryver.dev/blog/google-maps-as-an-angular-component#googlemap -->
