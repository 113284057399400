import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppRightPanelComponent } from './app-rightpanel/app-rightpanel.component';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';

// Application Components
import { AppMainComponent } from './app-main/app-main.component';
import { AppMenuitemComponent } from './app-menu/app-menuitem.component';
import { AppConfigComponent } from './app-config/app-config.component';

// Application services
import { MenuService } from './app-menu/app-menu.service';
import { NgClass } from '@angular/common';

@NgModule({
	declarations: [
		AppFooterComponent,
		AppMenuComponent,
		AppRightPanelComponent,
		AppMainComponent,
		AppMenuitemComponent,
		AppConfigComponent,
	],

	exports: [
		AppMainComponent
	],

	imports: [
		NgClass,
		FormsModule,
		RouterModule,
		CalendarModule,
		CheckboxModule,
		TabViewModule,
		RadioButtonModule,
		RippleModule,
		SidebarModule,
		InputSwitchModule,
	],
	providers: [
		MenuService
	]

})

export class LayoutModule {

}
