import {
	Component,
	ComponentRef,
	forwardRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	SimpleChanges,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';

import { PatternsServiceGql } from './patterns.service.gql';
import { PatternsProcessorService } from './patterns-processor.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Pattern, PatternModel, PatternType, MatchMode } from '../../../domain';
import { DialogModule } from 'primeng/dialog';
import { PatternMode } from '../shared';
import { SubpatternDialogComponent } from '../subpatterns-dialog/subpattern-dialog.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
	standalone: true,
	selector: 'br-pattern',
	template: '<ng-container #content></ng-container>',
	providers: [
		PatternsServiceGql,
		DialogService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PatternComponent),
			multi: true,
		},
	],
	imports: [DialogModule, FormsModule, TooltipModule],
})
export class PatternComponent
	implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
	private _mode: PatternMode;
	@Input()
	public set mode(value: PatternMode) {
		if (this._mode != value) {
			this._mode = value;
		}
	}

	public get mode() {
		return this._mode;
	}

	@Input() public patternId: number;
	@Input() public type: string;
	@Input() public settings: any;

	@ViewChild('content', { read: ViewContainerRef, static: true })
	protected contentContainer: ViewContainerRef;

	ref: DynamicDialogRef | undefined;
	selectedSubpattern: any;
	protected subPatterns: SelectItem<number>[] = [];

	private componentRef: ComponentRef<any> | undefined;
	private model: any; // data for pattern
	public pattern: Pattern;
	private patternModel: PatternModel;

	public changeFn: any;
	public touchedFn: any;

	public constructor(
		private svc: PatternsServiceGql,
		private kcs: KeycloakService,
		@Optional() private pSvc: PatternsProcessorService,
		private dialogSvc: DialogService
	) {
	}

	ngOnInit() {
		this.loadAndCreate();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			// we are selecting a different pattern for an exam template
			changes.patternId &&
			changes.patternId.previousValue
		) {
			this.cleanComponentRef();
			// all patterns should be recreated
			this.loadAndCreate();
		} else if (
			changes.mode &&
			changes.mode.previousValue &&
			this.componentRef
		) {
			// we are toggling mode (view / edit)
			// propagate change into pattern-content and its child components
			this.componentRef.instance.mode = changes.mode.currentValue;
		}
	}

	private loadAndCreate() {
		if (this.patternId) {
			this.svc.get(this.patternId).subscribe((pattern) => {
				this.pattern = pattern;
				this.patternModel = pattern.model;
				if (this.pSvc) {
					this.pSvc.process(this.pattern, this.model);
				}
				this.createContent();
				this.loadSubPatterns();
			});
		}
	}

	public writeValue(value: any) {
		if (value && this.model != value) {
			this.model = value;

			if (this.pattern?.type == PatternType.Main) {
				this.cleanComponentRef();
				this.loadAndCreate();
			}
		}
	}

	public registerOnChange(fn: any) {
		this.changeFn = fn;
	}

	public registerOnTouched(fn: any) {
		this.touchedFn = fn;
	}

	private async createContent() {
		if (!this.model || !this.pattern || this.componentRef) {
			// hold on until we get data, pattern
			// or if content has already been created
			return;
		}
		const component = await this.setupContentComponent(
			this.pattern.content
		);
		this.componentRef = this.contentContainer.createComponent(component, {
			injector: this.contentContainer.injector,
		});
	}

	private async setupContentComponent(content: string = '') {
		const defs = await import('./pattern-content.component');
		const currentMeta = { ...defs.componentMetadata };
		currentMeta.template = content;
		if (!currentMeta.providers) {
			currentMeta.providers = [];
		}
		currentMeta.providers.push(
			{ provide: defs.PatternContentModel, useValue: this.model },
			{ provide: defs.PatternContentSettings, useValue: this.settings },
			{ provide: defs.PatternContentMode, useValue: this.mode },
			{
				provide: defs.CurrentUser,
				useValue: { roles: this.kcs.getUserRoles() },
			}
		);
		let cmp = Component(currentMeta);
		return cmp(defs.PatternContentComponent);
	}

	private cleanComponentRef() {
		if (this.componentRef) {
			this.componentRef.destroy();
			delete this.componentRef;
		}
	}

	public ngOnDestroy() {
		this.cleanComponentRef();
	}

	@HostListener('dblclick')
	protected openSubpatternDialog() {
		if (
			this.type &&
			this.type !== PatternType.Main &&
			this.mode == 'template'
		) {
			this.selectedSubpattern = this.subPatterns.filter(
				(item) => item.title == this.pattern.type
			);

			this.ref = this.dialogSvc.open(SubpatternDialogComponent, {
				header:
					$localize`Select` +' '+
					this.pattern?.type.toLowerCase().replaceAll('_', ' '),
				draggable: true,
				data: { subPatterns: this.selectedSubpattern }, // Pass the selectedSubPatterns data to the dialog
				dismissableMask: true,
				footer: $localize`Current subpattern is` + `: ${this.pattern?.name}`,
			});
			this.ref.onClose.subscribe(
				(selectedSubpattern: SelectItem<number>) => {
					if (selectedSubpattern) {
						this.selectedSubpattern = selectedSubpattern;
						this.model.data[this.type] = this.selectedSubpattern.value;
					}
				}
			);
		}
	}

	private loadSubPatterns() {
		if (this.subPatterns?.length) {
			return;
		}
		const params: LazyLoadEvent = {
			filters: {
				type: {
					matchMode: MatchMode.NotEquals,
					value: PatternType.Main.toLowerCase(),
				},
			},
			sortField: 'name',
			sortOrder: 1,
		};
		this.svc.getAll(params).subscribe((options) => {
			this.subPatterns = options
				.filter((o) => o.model == this.patternModel)
				.map((o) => ({ value: o.id, label: o.name, title: o.type, } as SelectItem));
		});
	}
}
