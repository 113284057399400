<div id="layout-config" class="layout-config" [ngClass]="{'layout-config-exit-done': !appMain.configActive,
  'layout-config-enter-done': appMain.configActive}"
	[@children]="appMain.configActive ? 'visibleAnimated' : 'hiddenAnimated'">
	<div class="layout-config-content">

		<p-tabView id="config-form-tabs">
			<p-tabPanel i18n-header header="Light or Dark">
				<h1 i18n>Light or Dark</h1>
				<div class="grid justify-content-center align-items-center">
					<div class="col col-fixed">
						<a href="#" class="layout-config-option layout-config-option-image"
							(click)="changeLayout($event, false)">
							<img src="assets/layout/images/configurator/choice-light.png" alt="mirage-layout"
								style="width:100%" />
							<span class="layout-config-option-text" i18n>Light</span>
							@if (!appMain.darkMode) {
								<i class="pi pi-check"></i>
							}
						</a>
					</div>
					<div class="col col-fixed md:offset-1">
						<a href="#" class="layout-config-option layout-config-option-image"
							(click)="changeLayout($event, true)">
							<img src="assets/layout/images/configurator/choice-dark.png" alt="mirage-layout"
								style="width:100%" />
							<span class="layout-config-option-text" i18n>Dark</span>
							@if (appMain.darkMode) {
								<i class="pi pi-check"></i>
							}
						</a>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel i18n-header header="Menu">
				<div class="layout-config-subtitle" i18n>Mode</div>
				<div class="grid">
					<div class="col col-fixed">
						<a href="#" class="layout-config-option layout-config-option-image"
							(click)="changeMenuToHorizontal($event,true)">
							<img src="assets/layout/images/configurator/menu/horizontal.png" alt="mirage-layout"
								style="width:100%" />
							<span class="layout-config-option-text" i18n>Horizontal</span>
							@if (appMain.horizontalMenu) {
								<i class="pi pi-check"></i>
							}
						</a>
					</div>
					<div class="col col-fixed">
						<a href="#" class="layout-config-option layout-config-option-image"
							(click)="changeMenuToHorizontal($event,false)">
							<img src="assets/layout/images/configurator/menu/overlay.png" alt="mirage-layout"
								style="width:100%" />
							<span class="layout-config-option-text" i18n>Overlay</span>
							@if (!appMain.horizontalMenu) {
								<i class="pi pi-check"></i>
							}
						</a>
					</div>
				</div>
				<div class="layout-config-subtitle" i18n>Color</div>
				<div class="grid">
					@for (menuColor of menuColors; track menuColor) {
						<div class="col col-fixed">
							<a href="#" class="layout-config-option layout-config-option-image"
								(click)="changeMenuColor($event,menuColor.name)">
								<img src="assets/layout/images/configurator/menu/{{menuColor.name}}.png"
									alt="{{menuColor.name}}" />
								<span class="layout-config-option-text">{{menuColor.name}}</span>
								@if (menuColor.name === appMain.menuColorMode) {
									<i class="pi pi-check"></i>
								}
							</a>
						</div>
					}
				</div>
				<div class="layout-config-subtitle" i18n>Theme</div>
				<div class="grid">
					@for (menuTheme of selectedColorOptions; track menuTheme) {
						<div class="col col-fixed">
							<a href="#" class="layout-config-option layout-config-option-image layout-config-option-theme"
								(click)="changeMenuTheme($event,menuTheme.file)">
								<img src="assets/layout/images/configurator/menu/theme/{{menuTheme.image}}"
									alt="{{menuTheme.name}}" />
								@if (appMain.menuColorMode === 'custom' && 'layout-menu-'+menuTheme.file ===
								appMain.menuColor) {
									<i class="pi pi-check"></i>
								}
								@if (appMain.menuColorMode !== 'custom' && menuTheme.file === appMain.layoutColor) {
									<i class="pi pi-check"></i>
								}
							</a>
						</div>
					}
				</div>
			</p-tabPanel>
			<p-tabPanel i18n-header header="Components">
				<div class="grid">
					<div class="col-12 md:col-6">
						<div class="layout-config-subtitle" i18n>Input Style</div>
						<div class="formgroup-inline">
							<div class="field-radiobutton">
								<p-radioButton name="inputStyle" value="outlined" [(ngModel)]="appMain.inputStyle"
									inputId="inputStyle1"></p-radioButton>
								<label for="inputStyle1" i18n>Outlined</label>
							</div>
							<div class="field-radiobutton">
								<p-radioButton name="inputStyle" value="filled" [(ngModel)]="appMain.inputStyle"
									inputId="inputStyle2"></p-radioButton>
								<label for="inputStyle2" i18n>Filled</label>
							</div>
						</div>
					</div>
					<div class="col-12 md:col-6">
						<div class="layout-config-subtitle" i18n>Ripple Effect</div>
						<p-inputSwitch [ngModel]="appMain.ripple"
							(onChange)="appMain.onRippleChange($event)"></p-inputSwitch>
					</div>
				</div>
				<div class="layout-config-subtitle" i18n>Component Themes</div>
				<div class="grid">
					@for (componentTheme of componentThemes; track componentTheme) {
						<div class="col col-fixed">
							<a href="#" class="layout-config-option layout-config-option-image layout-config-option-theme"
								(click)="changeComponentTheme($event,componentTheme.file)">
								<img src="assets/layout/images/configurator/theme/{{componentTheme.image}}"
									alt="{{componentTheme.name}}" />
								@if (componentTheme.file === appMain.themeColor) {
									<i class="pi pi-check"></i>
								}
							</a>
						</div>
					}
				</div>
			</p-tabPanel>
		</p-tabView>
		<div class="buttons">
			<button pButton label="Cancel" i18n-label (click)="onConfigCloseClick(false)"></button>
			<button pButton label="Save" i18n-label (click)="onConfigCloseClick(true)"></button>
		</div>
	</div>
</div>
