<p-dialog [(visible)]="visible" i18n-header header="Send e-mail" [resizable]="false"
  [closeOnEscape]="true" [closable]="true" (onHide)="hide($event)" [modal]="true" appendTo="body">
  @if (fg) {
    <form [formGroup]="fg" class="grid">
      <div class="field p-fluid p-float-label col-12 mt-4">
        <input pInputText type="email" formControlName="recipient" />
        <label for="recipient" i18n class="required">E-mail</label>
      </div>
      <div class="field p-fluid col-12">
        <label for="subject" i18n class="required">Subject</label>
        <p-dropdown formControlName="emailSubject" [options]="patterns" (onChange)="setEmailContent($event)" [editable]="true"
        [autoDisplayFirst]="false" optionLabel="name" i18n-placeholder placeholder="[Please, enter or select...]"></p-dropdown>
      </div>
      <div class="field p-fluid col-12">
        <p-editor formControlName="emailContent" i18n-placeholder placeholder="Content..." [style]="{ 'min-height': '150px' }"></p-editor>
      </div>
      <div class="col-12 field grid">
        <button pButton class="sm:col-2 sm:col-offset-7 col-4 col-offset-3" type="reset" icon="mdi mdi-cancel" i18n-label label="Cancel" (click)="hide($event)"></button>
        <button pButton class="sm:col-2 col-4 col-offset-1" type="submit" icon="mdi mdi-email-send" i18n-label label="Send" (click)="send()"></button>
      </div>
    </form>
  }
</p-dialog>
