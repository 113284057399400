import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

@Directive({
	standalone: true,
	selector: "[customPattern][formControlName], [customPattern][formControl], [customPattern][ngModel]",
	providers: [{
		provide: NG_VALIDATORS, useExisting: CustomPatternValidatorDirective, multi: true
	}]
})
export class CustomPatternValidatorDirective implements Validator {

	@Input()
	customPattern: { regex: string | RegExp, message: string };

	registerOnValidatorChange?(fn: () => void): void {
	}

	validate(control: AbstractControl<any, any>): ValidationErrors {
		const errors = Validators.pattern(this.customPattern.regex)(control);
		if (errors) {
			errors.pattern.message = this.customPattern.message;
		}
		return errors;
	}
}
