import { InjectionToken } from "@angular/core";

export interface PageInfo {
	totalCount: number;
	pageIndex: number;
	pageSize: number;
	totalPageCount: number;
	hasNext: boolean;
	hasPrev: boolean;
	displayXtoYofZ: string;
}

export interface PagedList<T> {
	items: T[];
	pageInfo: PageInfo;
}

type StrResultFn = (data?: any) => string | number;

type TooltipDefinition = {
	value: string | StrResultFn,
	position?: 'right'| 'left' | 'top' | 'bottom',
	escape?: boolean
};

type IconDefinition = {
	value: (string | StrResultFn),
	position?: ('left' | 'right'),
} ;

export interface ColumnDefinition {
	field?: string | number | boolean | StrResultFn ,
	icon?: IconDefinition | string | StrResultFn,
	tooltip?: TooltipDefinition | StrResultFn,
	overlayContent?: string | StrResultFn,
	htmlContent?:  string | StrResultFn | any,

	header?: string,
	headerIcon?: IconDefinition | string ,
	headerTooltip?: TooltipDefinition | string,

	sortable?: string | string[],
	position?: number,
	size?: number,
	visible?: boolean,
	key: string,
	link?: string | StrResultFn,
	editable?: boolean,
	styleClass?: StrResultFn | string[] | Set<string> | { [klass: string]: any; }, // for NgClass
}

export interface ColumnsDefinitions {
	columns: ColumnDefinition[],
	responsive?: {
		"sm"?: string[],
		"md"?: string[],
		"lg"?: string[],
		"xl"?: string[],

		"sm:rev"?: string[],
		"md:rev"?: string[],
		"lg:rev"?: string[],
		"xl:rev"?: string[],
	}
}

export interface RowSelectionEvent {
	originalEvent: Event,
	data: any
	type: 'row' | 'radiobutton' | 'checkbox'
	index: number
}

export interface ColumnsUserSettings {
	columnOrder?: string[],
	// columnWidth: string[], this is for PrimeNG Table component internal use
	columnVisible?: string[],
}

export interface TableUserSettings extends ColumnsUserSettings {

}

export interface TableWrapper {
	setColumns(columns: ColumnDefinition[]): void;
}

export interface VisibilityChanged {
	newValue?: boolean,
	itemId?: number,
	groupId?: string,
}

export const COLUMNS_DEFINITIONS = new InjectionToken<ColumnsDefinitions>('COLUMNS_DEFINITIONS');
export const TABLE_WRAPPER = new InjectionToken<TableWrapper>('TABLE_WRAPPER');
