import { KeycloakOptions } from "keycloak-angular";
import { AbstractEnvironment } from "@biorec/common";

export class Environment extends AbstractEnvironment {
	
	static override readonly production = false;
	static override readonly backendUrl = 'https://api.biorec.org/admin';

	static override readonly keycloakOptions = {
		config: {
			url: 'https://iam.biorec.org',
			realm: 'mikroskopisi-users',
			clientId: 'biorec-frontend',
		} as Keycloak.KeycloakConfig,
		initOptions: {
			onLoad: 'check-sso',
			silentCheckSsoRedirectUri: window.location.origin
				+ window.location.pathname.replace(/^(\/[a-z]{2})($|\/.*)/, '$1') 
				+ '/assets/check-sso.html',
		},
		loadUserProfileAtStartUp: false,
		bearerExcludedUrls: []
	 } as KeycloakOptions;
}
