import { AbstractForm } from "./abstract-form";
import { DefaultDialogComponent, DefaultDialogConfig } from "../../dialog";
import { Type } from "@angular/core";

export class AbstractFormDialogConfig<T extends AbstractForm<any>> extends DefaultDialogConfig<T> {

}

export const abstractFormDialogConfig = (cmp: Type<AbstractForm<any>>, header: string) => {

	function canDeactivateForm(dialog: DefaultDialogComponent<AbstractForm<any>>) {
		return dialog.getComponent().onDeactivate();
	}
	
	function onFormCreated(dialog: DefaultDialogComponent<AbstractForm<any>>) {
		dialog.getComponent().cancel.subscribe(dialog.close.bind(dialog));
		dialog.getComponent().close.subscribe(dialog.close.bind(dialog));
	}

	const conf = new AbstractFormDialogConfig(cmp, header);
	conf.closeHandler = canDeactivateForm;
	conf.onChildCreated = onFormCreated;
	return conf;
}
