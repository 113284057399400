import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DateTime, DurationUnit, DurationLikeObject, Duration } from 'luxon';
import { FilterMetadata } from 'primeng/api';


function validatePeriod(period: Date[] | FilterMetadata, maxDuration: number, durationUnit: DurationUnit) {
	if ((<FilterMetadata>period).value) {
		const filterValue = (<FilterMetadata>period).value as string[];

		period = filterValue.map(p => DateTime.fromISO(p).toJSDate());
	}

	if (
		period instanceof Array &&
		period.length == 2 &&
		period.filter(t => t && t instanceof Date).length == 2 &&
		DateTime.fromJSDate(period[1])
			.diff(DateTime.fromJSDate(period[0]), durationUnit)
			.as(durationUnit) > maxDuration
	) {
		return { durationExceeded: true };
	}
	return null;
}

@Directive({
	selector: '[maxDurationValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: MaxDurationValidatorDirective, multi: true }
	],
	standalone: true,
})
export class MaxDurationValidatorDirective implements OnInit, Validator {

	private maxDuration = 60;
	private durationUnit: keyof DurationLikeObject = 'days';

	@Input('maxDurationValidator') duration: DurationLikeObject;
	@Input() validationMessage: string;

	ngOnInit() {
		if (this.duration) {
			let k: keyof DurationLikeObject;
			for (k in this.duration) {
				this.durationUnit = k;
				this.maxDuration = Duration.fromObject(this.duration).as(k);
			}
		}
	}

	registerOnValidatorChange?(fn: () => void): void {}

	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		if (control.value && validatePeriod(control.value, this.maxDuration, this.durationUnit)) {
			return { maxDuration: {count: this.maxDuration, type: this.durationUnit} }
		}
		return null;
	}
}
