import { Component, OnInit, Inject } from '@angular/core';
import { AppMainComponent } from '../app-main/app-main.component';
import { MenuInterface, AppMenu } from './app-menu.interface';
import { KeycloakService } from 'keycloak-angular';

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.component.html',
})
export class AppMenuComponent implements OnInit {

	protected model: any[];
	protected powered: boolean;

	constructor(
		kcSvc: KeycloakService,
		public appMain: AppMainComponent,
		@Inject(AppMenu) private menu: MenuInterface
	) {
		this.powered = kcSvc.isUserInRole('powered');
	}

	ngOnInit() {
		this.model = this.menu.getMenu();
	}

	protected onMenuClick() {
		this.appMain.menuClick = true;
	}
}
