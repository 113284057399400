<span class="p-float-label">
	<p-dropdown [ngModel]="model.value" (ngModelChange)="onChangeHandler($event)" (onFocus)="onInputFocus($event)"
		(onBlur)="onInputBlur($event)" [required]="required" [autoDisplayFirst]="false" [showClear]="true"
		[options]="items" #controlObj="ngModel">
	</p-dropdown>
	<label [for]="name" [ngClass]="{ 'required': required }">
		{{ label }}@if (required && controlObj.invalid && (controlObj.dirty || controlObj.touched)) {
			<ng-container i18n> is required!</ng-container>
		}
	</label>
</span>
