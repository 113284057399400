import { Component, forwardRef } from '@angular/core';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SettingsService } from '../../../services';
import { ColumnDefinition } from '../common';

@Component({
	standalone: true,
	selector: 'br-columns-selector',
	templateUrl: './table-columns-selector.component.html',
	imports: [
		FormsModule,
		CheckboxModule,
		TriStateCheckboxModule,
		OrderListModule,
		OverlayPanelModule,
		ButtonModule,
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TableColumnSelectorComponent),
			multi: true,
		},
	],
})
export class TableColumnSelectorComponent implements ControlValueAccessor {
	public changeFn: any;
	public touchedFn: any;
	protected model: ColumnDefinition[];

	protected originalModel: ColumnDefinition[];
	protected selectedColumns: ColumnDefinition[] = [];

	private _masterSelect: boolean | null = null;
	protected get masterSelect() {
		return this._masterSelect;
	}
	protected set masterSelect(value: boolean | null) {
		if (this._masterSelect === true) {
			this._masterSelect = null;
			this.model.forEach((c) => (c.visible = false));
		} else {
			this._masterSelect = true;
			this.model.forEach((c) => (c.visible = true));
		}
		this.setSelection();
	}

	constructor(private sSvc: SettingsService) {}

	private copyModel(origin: ColumnDefinition[]) {
		let destination: ColumnDefinition[];
		if (origin && Array.isArray(origin)) {
			destination = [...origin].map((c) => {
				return { ...c };
			});
		}
		return destination;
	}

	private setSelection() {
		if (this.model && Array.isArray(this.model)) {
			this.selectedColumns = this.model.filter((c) => c.visible);
			this.setMasterSelect();
		}
	}

	private setMasterSelect() {
		if (this.selectedColumns.length == this.model.length) {
			this._masterSelect = true;
		} else if (this.selectedColumns.length) {
			this._masterSelect = false;
		} else {
			this._masterSelect = null;
		}
	}

	protected applySelection() {
		this.model.forEach(
			(c) => (c.visible = this.selectedColumns.includes(c))
		);
		this.setMasterSelect();
	}

	protected cancel() {
		this.model = this.copyModel(this.originalModel);
		this.setSelection();
		this.changeFn(this.model);
	}

	protected saveSelection() {
		this.sSvc.set({
			columnVisible: this.model
				.filter((c) => c.visible)
				.map((c) => c.key),
			columnOrder: this.model.map((c) => c.key),
		});
		this.originalModel = this.copyModel(this.model);
		this.changeFn(this.model);
	}

	public writeValue(value: ColumnDefinition[]) {
		this.model = value;
		this.originalModel = this.copyModel(value);
		this.setSelection();
	}
	public registerOnChange(fn: any) {
		this.changeFn = fn;
	}
	public registerOnTouched(fn: any) {
		this.touchedFn = fn;
	}
}
