@if (icon && !focus && !model.value) {
	<div class="icon-container">
		<i [class]="icon"></i>
	</div>
}
<p-calendar [ngModel]="model.value" (ngModelChange)="onChangeHandler($event)" (onFocus)="onInputFocus($event)"
	(onBlur)="onInputBlur($event)" (onClose)="onCalendarClose()" [required]="required" [inputId]="name"
	selectionMode="range" dateFormat="dd.mm.yy" [showIcon]="showIcon" [showButtonBar]="showButtonBar"
	[minDate]="minDate" [maxDate]="maxDate" #controlObj="ngModel">
</p-calendar>
