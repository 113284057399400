import { Component } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {

	protected readonly currentYear: number;
	
/*	private tickTimer: NodeJS.Timer;
	private exp: DateTime;
	diff: string;
*/
	constructor(/*private kcs: KeycloakService*/) {
		this.currentYear = new Date().getFullYear();
	}
	
/*	ngOnInit() {
		this.exp = DateTime.fromSeconds(this.kcs.getKeycloakInstance()?.tokenParsed?.exp || 0);
		// each second tik - tok
		this.tickTimer = setInterval(()=> {
			this.diff = this.exp.diffNow(['minutes', 'seconds']).toFormat('mm:ss');
		}, 1000);
	}

	refresh() {
		this.kcs.updateToken().then(() => {
			this.ngOnInit();
		});
	}
	
	ngOnDestroy() {
		clearInterval(this.tickTimer);
	}
*/
}
