import { Pipe, PipeTransform } from '@angular/core';
import { toJsDate } from '../../../shared';

@Pipe({
  standalone: true,
  name: 'toJsDate'
})
export class ToJsDatePipe implements PipeTransform {
  public transform(date: string | Date, fromFormat?: string): any {
    return toJsDate(date, fromFormat);
  }
}
