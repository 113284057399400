import { i18n } from '@biorec/i18n';
import { DateTime } from "luxon";
import { FilterMetadata, SelectItem } from 'primeng/api';
import { SearchParams, SearchFilter } from './search-params';
import { TableLazyLoadEvent } from 'primeng/table';
import { Environment } from 'src/admin/environments/environment';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';


export function enumToSelectItem(input: any) {
	let items: Array<SelectItem> = new Array();
	if (input != null) {
		Object.keys(input).forEach((k) => {
			if (!k || k.match(/toName/i)) {
				return;
			}
			let label = k;
			if (input.toName) {
				label = input.toName(input[k]);
			} else if (i18n[k]) {
				label = i18n[k];
			}
			items.push({
				value: input[k],
				label: label,
			});
		});
	}
	return items;
}

// May have extra filters
// COnver autocomplete to global filter
// Can add row = 10
export function adaptAutoCompleteParams(params: AutoCompleteCompleteEvent): SearchParams {
	const { query, originalEvent, ...other } = params;
	const result: SearchParams = {
	  first: 0,
	  rows: Environment.maxAutocompleteItems,
	  globalFilter: query,
	  ...other,
	};

	if (result.globalFilter && Array.isArray(result.globalFilter)) {
	  result.globalFilter = result.globalFilter.join(' ');
	}

	return result;
  }

export function adaptParams(params: TableLazyLoadEvent): SearchParams {
	const { filters, sortField, sortOrder, multiSortMeta, ...other } = {
		...params,
	};
	const result = other as SearchParams;
	if (filters) {
		result.filters = Object.keys(filters)
			.map((k) => {
				if (!filters[k]) {
					return null;
				}
				const f = <FilterMetadata>filters[k];
				return {
					field: k,
					matchMode: f.matchMode,
					value:
						k == 'global' && Array.isArray(f.value)
							? f.value.join(' ')
							: f.value,
				} as SearchFilter;
			})
			.filter((f) => f);
	}
	result.sorting = multiSortMeta || [];
	if (sortField) {
		if (Array.isArray(sortField)) {
			sortField.forEach((f) =>
				result.sorting.push({ field: f, order: sortOrder || 1 })
			);
		} else {
			result.sorting.push({ field: sortField, order: sortOrder || 1 });
		}
	}
	if (result.globalFilter && Array.isArray(result.globalFilter)) {
		result.globalFilter = result.globalFilter.join(' ');
	}
	return <SearchParams>result;
}

export function prepareDownload(
	content: number[],
	type: string,
	filename: string
) {
	const bytes = Uint8Array.from(content);
	const blob = new Blob([bytes.buffer], { type: type });
	const a = document.createElement('a');
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export function resolveBooleanToIcon(condition: any) {
	return condition ? 'check' : 'minus';
}

export function resolveBooleanToEntity(condition: any) {
	return condition ? '\u2714' : '\u2212';
}

export type SizeSymbol =
	| 'xl'
	| 'lg'
	| 'md'
	| 'sm'
	| 'sm:rev'
	| 'md:rev'
	| 'lg:rev'
	| 'xl:rev';

export interface SizeOption {
	symbol: SizeSymbol;
	check: () => boolean;
}

export const SizeUtils: { [key: string]: SizeOption } = {
	// order is important !!!
	atLeastExtraLarge: {
		symbol: 'xl',
		check: () => document.documentElement.clientWidth > 1199,
	},
	atLeastLarge: {
		symbol: 'lg',
		check: () => document.documentElement.clientWidth > 991,
	},
	atLeastMiddle: {
		symbol: 'md',
		check: () => document.documentElement.clientWidth > 767,
	},
	atLeastSmall: {
		symbol: 'sm',
		check: () => document.documentElement.clientWidth > 575,
	},

	upToSmall: {
		symbol: 'sm:rev',
		check: () => document.documentElement.clientWidth < 576,
	},

	upToMiddle: {
		symbol: 'md:rev',
		check: () => document.documentElement.clientWidth < 768,
	},
	upToLarge: {
		symbol: 'lg:rev',
		check: () => document.documentElement.clientWidth < 992,
	},
	upToExtraLarge: {
		symbol: 'xl:rev',
		check: () => document.documentElement.clientWidth < 1200,
	},
};

export function ucFirst(value: string) {
	return value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase();
}

export function dateFormat(date: string | Date, format: string, fromFormat?: string) {
  let result: DateTime;
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    result = DateTime.fromJSDate(date);
  } else {
    // all around `fromFormat` should be checked very well
    if (typeof (date) == 'string') {
      // 2018-01-24T18:29:46.280752+02:00[Europe/Sofia]
      const regex = /(\d{4}\-\d{2}\-\d{2})T(\d{2}\:\d{2}\:\d{2})(\.\d+)?(Z|(\+\d{2}\:\d{2}))\[([^\]]+)\]/;
      const matches = date.match(regex);
      if (matches && matches.length) {
        date = matches[1] + (fromFormat ? ' ' : 'T') + matches[2] + matches[4];
      }
    }
    if (fromFormat) {
      result = DateTime.fromFormat(<any>date, fromFormat)
    } else {
      result = DateTime.fromISO(<any>date);
    }
  }
  return result.toFormat(format);
}

export function toJsDate(date: string | Date, fromFormat?: string): Date {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return date;
  }

  // 2018-01-24T18:29:46.280752+02:00[Europe/Sofia]
  const regex = /(\d{4}\-\d{2}\-\d{2})T(\d{2}\:\d{2}\:\d{2})(\.\d+)?(Z|(\+\d{2}\:\d{2}))\[([^\]]+)\]/;
  const matches = date.match(regex);
  if (matches && matches.length) {
    date = matches[1] + (fromFormat ? ' ' : 'T') + matches[2] + matches[4];
  }
  if (fromFormat) {
    return DateTime.fromFormat(<any>date, fromFormat).toJSDate();
  }
  return DateTime.fromISO(<any>date).toJSDate();
}
