import { Component, DoCheck, Input, QueryList, ViewChildren, inject } from "@angular/core";
import { ControlValueAccessor, NgControl, NgModel } from "@angular/forms";

declare type TouchedFn = () => void;
declare type ChangeFn<T extends any> = (value: T) => void;

@Component({template: ''})
export abstract class AbstractValueAccessor<T> implements ControlValueAccessor, DoCheck {

  @ViewChildren(NgModel)
  protected childControls: QueryList<NgModel>;

  @Input()
  required: boolean;

  @Input()
  readonly: boolean;

  @Input()
  style: Object;

  @Input()
  styleClass: string;

  protected focus: boolean;
  protected value:  any = null;
  protected disabled: boolean = true;
  protected onChange: ChangeFn<T> = (_: T) => { };
  protected onTouch: TouchedFn = () => { };

  protected control = inject(NgControl);

  constructor() {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  protected onInputFocus(event: any) {
    this.focus = true;
  }

  protected onInputBlur(event: any) {
    this.focus = false;
    this.markAsTouched(event);
  }

  writeValue(value: T) {
    this.value = value;
  }

  registerOnChange(fn: ChangeFn<T>) {
    this.onChange = fn;
  }

  registerOnTouched(fn: TouchedFn) {
    this.onTouch = fn;
  }

  protected onValueChange(value: any) {
    this.value = value;
    this.onChange(this.value);
  }

  protected markAsTouched(event: any) {
    this.onTouch();
  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled != this.disabled) {
      this.disabled = isDisabled;
    }
  }

  ngDoCheck() {
    if (!this.childControls?.length || !this.control) {
      return;
    }
    for (let c of this.childControls) {
      if (c.untouched && this.control.touched) {
        c.control.markAsTouched();
      }
    }
  }
}
