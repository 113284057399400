// @deprecated - should be removed and backend should serve its own translations
export const i18n: { [key: string]: string } = {
	'Bank': $localize`Bank`,
	'Business address is required!': $localize`Business address is required!`,
	'Business name is required!': $localize`Business name is required!`,
	'Cash': $localize`Cash`,
	'Clinic is required!': $localize`Clinic is required!`,
	'Content is required!': $localize`Content is required!`,
	'Courier': $localize`Courier`,
	'Date is required': $localize`Date is required`,
	'Doctor is required!': $localize`Doctor is required`,
	'Email': $localize`E-mail`,
	'First name is required!': $localize`First name is required!`,
	'Group is required!': $localize`Group is required!`,
	'Insurance is required!': $localize`Insurance is required`,
	'Last name is required!': $localize`Last name is required!`,
	'Model is required!': $localize`Model is required!`,
	'Name is required!': $localize`Name is required!`,
	'Other': $localize`Other`,
	'Patient is required': $localize`Patient is required`,
	'Printed': $localize`Printed`,
	'Private': $localize`Private`,
	'Representative is required!': $localize`Representative is required!`,
	'Sample date is required': $localize`Sample date is required`,
	'Signature is required!': $localize`Signature is required!`,
	'Social security number is required!': $localize`Social security number is required!`,
	'Specialty is required!': $localize`Specialty is required!`,
	'Tax office is required!': $localize`Tax office is required!`,
	'Title is required!': $localize`Title is required!`,
	'Type is required!': $localize`Type is required!`,
	'VAT number is required!': $localize`VAT number is required!`,
	'Tag has not been added!': $localize`Tag has not been added!`,
	'Tag has not been removed!': $localize`Tag has not been removed!`,
	'Partner could not be deleted!': $localize`Partner could not be deleted!`,
	'Not found!': $localize`Not found!`,
	'Execution error!': $localize`Execution error!`,
	'Please, contact support!': $localize`Please, contact support!`,
	'Patient could not be deleted!': $localize`Patient could not be deleted!`,
	'ANNULLED': $localize`ANNULLED`,
	'CLOSED': $localize`CLOSED`,
	'OPENED': $localize`OPENED`,
};
