import {Directive, inject, Input, OnDestroy, OnInit} from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { SettingsService } from "../../services";
import { ColumnsUserSettings, COLUMNS_DEFINITIONS } from "./common";
import { ColumnDefinition } from './common';

@Directive({
	standalone: true,
})
export class ColumnsSetupDirective implements OnInit, OnDestroy {

	// list with columns IDs that are allowed to be visible in current context
	@Input() allowedColumns!: string | string[];
	// list with columns IDs that are denied to be visible in current context
	@Input() deniedColumns!: string | string[];

	private subscriptions = new Subscription();
	private columnsChanged: ReplaySubject<ColumnDefinition[]> = new ReplaySubject(1);

	private definitions = inject(COLUMNS_DEFINITIONS);
	private sSvc = inject(SettingsService, { optional: true });

	constructor() { }

	ngOnInit() {
		if (!this.deniedColumns) {
			this.deniedColumns = [];
		}
		if (!Array.isArray(this.deniedColumns)) {
			this.deniedColumns = this.deniedColumns.split(/\s*,\s*/);
		}

		if (!this.allowedColumns) {
			this.allowedColumns = [];
		}
		if (!Array.isArray(this.allowedColumns)) {
			this.allowedColumns = this.allowedColumns.split(/\s*,\s*/);
		}
		this.redefineColumns();
		if (this.sSvc) {
			this.sSvc.onChanges$.subscribe((settings: ColumnsUserSettings) => {
				this.applySettings(settings);
			});
		} else {
			this.applySettings();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public redefineColumns():void {
		this.definitions.columns = this.definitions.columns.filter(c =>
			!this.deniedColumns.includes(c.key) && (!this.allowedColumns.length || this.allowedColumns.includes(c.key))
		);
	}

	private applySettings(settings: ColumnsUserSettings | null = null): void {
		// process user defined visibility
		this.definitions.columns.forEach(c => {
			c.visible = !settings?.columnVisible?.length || settings.columnVisible.includes(c.key);
		});
		// sort according to user settings
		if (settings?.columnOrder?.length) {
			this.definitions.columns
				.sort((c1, c2) => settings.columnOrder.indexOf(c1.key) - settings.columnOrder.indexOf(c2.key));
		}
		this.columnsChanged.next([...this.definitions.columns]);
	}

	registerOnChange(fn: (columns: ColumnDefinition[]) => any): Subscription {
		return this.columnsChanged.subscribe(fn);
	}
}
