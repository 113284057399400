<div class="field pt-4">
  <div class="p-float-label">
    <ng-content></ng-content>
    <label [ngStyle]="style">
      {{ label }}
      @if (control?.touched) {
        {{ control.errors | readableErrors }}
      }
    </label>
  </div>
</div>
