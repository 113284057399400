import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DefaultDialogComponent } from "./default-dialog.component";
import { Observable } from "rxjs";
import { Type } from "@angular/core";

export class DefaultDialogConfig<T> extends DynamicDialogConfig {
	closeHandler: (cmp: DefaultDialogComponent<T>) => Promise<boolean> | Observable<boolean> | boolean;
	onChildCreated: (cmp: DefaultDialogComponent<T>) => void;
	override style = {
		'min-width': '400px',
		'max-width': '98%'
	};

	override autoZIndex = true;
	override closable = true;
	override closeOnEscape = true;
	override dismissableMask = true;
	override modal = true;
	override resizable = true;
	override draggable = true;

	navigateOnClose = ['../'];

	getComponent() {
		return this.component;
	}

	constructor(private component: Type<T>, header: string) {
		super();
		this.header = header;
	}
}

