import { ControlValueAccessor } from "@angular/forms";
import { FilterMetadata } from "primeng/api";

import { MatchMode } from '../../../domain';

/**
	This class should be used as base for all other filter components
 */
export abstract class AbstractFilterComponent implements ControlValueAccessor {

	protected model: FilterMetadata = {};

	protected disabled: boolean = false;
	protected touched: boolean = false;
	protected focus: boolean = false;

	/** Match mode */
	protected matchMode: MatchMode | string = MatchMode.Equals;

	onModelTouched: Function = (_: any) => { };
	onModelChange: Function = (_: any) => { };

	/**
	 * Default behavior to accept the initial value of the component
	 */
	writeValue(value: FilterMetadata): void {
		if (value) {
			if (value.matchMode != this.matchMode) {
				value.matchMode = this.matchMode;
			}
		} else {
			value = {
				matchMode: this.matchMode
			}
		}
		this.model = value;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	registerOnTouched(fn: any): void {
		this.onModelTouched = fn;
	}

	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	protected onInputFocus(event: any) {
		this.focus = true;
	}

	protected onInputBlur(event: any) {
		this.focus = false;
		this.markAsTouched();
	}

	protected markAsTouched() {
		if (!this.touched) {
			this.onModelTouched();
			this.touched = true;
		}
	}

	/** Default handler for ngModelChange, if no additional processing required */
	protected onChangeHandler(value: any) {
		if (this.model.value != value) {
			this.model.value = value;
			if (!this.model.value || !this.model.value.toString().length) {
				// emit empty data
				this.onModelChange(null);
				return;
			}
			this.onModelChange(this.model);
		}
	}
}
