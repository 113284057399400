import { NgStyle } from '@angular/common';
import { Component, ContentChild, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessagePipe } from '../../common';

@Component({
	standalone: true,
	imports: [NgStyle, ValidationMessagePipe],
	selector: 'br-float-label',
	templateUrl: './float-label.component.html',
	styles: [
		`
			label {
				white-space: nowrap;
			}
		`,
	],
})
export class FloatLabelComponent {
	@Input({ required: true })
	label: string;

	error: string;

	@Input()
	style: { [key: string]: string };

	@ContentChild(NgControl)
	protected control: NgControl;

	constructor() {}
}
