import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessagePipe } from '../../common';

@Component({
	standalone: true,
	imports: [NgClass, NgTemplateOutlet, ValidationMessagePipe],
	selector: 'br-static-label',
	templateUrl: './static-label.component.html',
	styles: [
		`
			div.ep-static-label {
				display: block;
				position: relative;

				label {
					position: absolute;
					left: 0.75rem;
					top: 0.25rem;
					white-space: nowrap;
				}
			}
		`,
	],
})
export class StaticLabelComponent {

	@Input({ required: true })
	label: string;

	protected error: string;

	@ContentChild(NgControl)
	protected control: NgControl;

	@ContentChild('suffixIcon', { static: false })
	protected suffixIcon: TemplateRef<null>;

	constructor() {}
}
