// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakOptions } from "keycloak-angular";

export abstract class AbstractEnvironment {
	
	/** should be false in production, else patterns will not compile(null undefined elsewhere) */
	static readonly production: boolean | null | undefined;
	/** the base backaned URL */
	static readonly backendUrl: string;

	/** options available for pagination */
	static readonly resultsPerPageOptions = [5, 10, 25, 50, 100];
	
	/** default pagination option */
	static readonly defaultResultsPerPage = 10;
	
	/** max results to select for dropdowns */
	static readonly maxDropdownItems = 250;
	/** max results to select for autocompletes */
	static readonly maxAutocompleteItems = 250;
	
	/** languages available for the system interface */
	static readonly langs = [
		{ label: 'Ελληνικά', value: 'el', flag: 'assets/images/gr_flag.webp' },
		{ label: 'English', value: 'en', flag: 'assets/images/uk_flag.png' },
	];
	
	/** options for keycloak realm that serves user accounts */
	static readonly keycloakOptions: KeycloakOptions;
}
