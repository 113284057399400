import { PrintableContent } from "../../../shared";
import { PdfServiceGql } from "../../../services";

export abstract class AbstractPatternWrapper implements PrintableContent {

	private readonly cleanRegex = [
		// !! order of execution matters
		new RegExp('(<\![^>]+>)', 'g'), // comments
		new RegExp('(_ng[a-z-0-9\-]+="[^"]*")|(ng-reflect[a-z-0-9\-]+="[^"]*")', 'g'), // angular attributes
		new RegExp('(<\/?br-[^>]+>)', 'g') // local components
	];
	
	constructor(private pdfSvc: PdfServiceGql) {
	}
	
	protected abstract getContentElement(): HTMLBaseElement;
	
	private getContent():string {
		let content = (this.getContentElement()).innerHTML;
		this.cleanRegex.forEach(c => content = content.replace(c, ''));
		return content;
	}

	public getPrintableContent() {
		return `
			<!doctype html>
			<html>
				<head>
					<meta charset="utf-8">
					<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
					<style>
						p {
							margin: 0;
							line-height: 1rem;
						}
						@media print {
							@page { margin: 0; size: auto }
							
							.bottomFixed {
								position:fixed!important;
								bottom:10px!important;
							}
						}
					</style>
				</head>
				<body>
				${this.getContent()}
				</body>
			</html>\
		`;
	}
	
	public print() {
		const pw = window.open();
		pw.document.write(this.getPrintableContent());
		const styles = pw.document.createElement('link');
		styles.rel = 'stylesheet';
		styles.type = 'text/css';
		styles.href = window.document.baseURI + 'assets/fonts/fonts.css';
		pw.document.getElementsByTagName('head')[0].appendChild(styles);
		pw.addEventListener('load', () => {
			setTimeout(() => {
				pw.print();
				pw.close();
			}, 100);
		});
		pw.document.close();
	}
	
	public asPdf(fileName?: string) {
		return this.pdfSvc.generatePdf(this.getPrintableContent(), fileName);
	}
	
}