import {  AfterViewInit, Component, OnDestroy } from "@angular/core";
import { FiltersBrokerService, SettingsService, TabContainerComponent } from "@biorec/common";
import { Subscription } from "rxjs";


@Component({
	template: '',
})
export abstract class AbstractTabsComponent implements AfterViewInit, OnDestroy {
	
	protected abstract tabContainer: TabContainerComponent;
	
	protected subscriptions = new Subscription();
	
	protected selected: number;
	protected settings: any;
	
	constructor(fSvc: FiltersBrokerService, sSvc: SettingsService) {
		// this is a very dumb solution to force list loading with new filters
		// but at this moment there's no better
		this.subscriptions.add(
			sSvc.onChanges$.subscribe(settings => this.settings = settings || {})
		);
		this.subscriptions.add(
			fSvc.onChanges$.subscribe(filters => {
				if (this.tabContainer && this.selected != null) {
					// we will force filters update
					this.settings.filters = filters;
					this.settings.first = 0;
					this.subscriptions.add(
						sSvc.set(this.settings, true).subscribe(() => {
							this.selected = null;
							this.tabContainer.handleChange({ index: 0 });
						})
					);
				}
			})
		);
	}
	
	ngAfterViewInit() {
		this.tabContainer.addSelectionListener((idx: number) => this.selected = idx);
	}
	
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
