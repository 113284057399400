import { ReplaySubject } from "rxjs";
import { share } from 'rxjs/operators';
import { FiltersObject } from "./common";


export class FiltersBrokerService { 
	
	private _onChanges = new ReplaySubject<FiltersObject>(1);
	
	readonly onChanges$ = this._onChanges.pipe(share());
	
	emitChanges(filters: FiltersObject) {
		this._onChanges.next(filters);
	}
}