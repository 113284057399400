import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";

import { GraphQLClient } from "../../services";
import { Pattern, MatchMode } from "../../domain";
import { MailerSendData } from "./mailer-data";
import { PatternsListDocument, SendMailDocument } from '../../graphql/documents';

@Injectable()
export class MailerServiceGql {

	constructor(private client: GraphQLClient) {
	}

	public getMailerPatterns(patternModel: string): Observable<Pattern[]> {
		const params = {
			filters: [
				{
					field: 'model',
					matchMode: MatchMode.Equals,
					value: patternModel
				},
			],
			sorting: [{ field: 'name', order: 1 }]
		}
		const o = this.client.request(PatternsListDocument, { params: params })
			.pipe(map((result: any) => <Pattern[]>result.PatternsList), share());
		return o;
	}

	public send(data: MailerSendData): Observable<boolean> {
		const o = this.client.request(SendMailDocument, {data: data})
			.pipe(map((result: any) => !!result.SendMail.result), share());
		return o;
	}
}
