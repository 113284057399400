import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is expected to be used only for translations dynamic
 * templates, e.g. exams and invoices view / edit
 * !!! It is possible that we will not need it
 */
@Pipe({
	name: 'translate',
	standalone: true,
})
export class TranslatePipe implements PipeTransform {

	public transform(value: string): string {
		return value; // TODO: implement real translation
	}
}
