import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TableModule } from "primeng/table";
import { SelectItem } from "primeng/api";
import { DialogModule } from "primeng/dialog";

@Component({
	standalone: true,
	selector: 'app-subpattern-dialog',
	templateUrl: './subpattern-dialog.component.html',
	styleUrls: ['./subpattern-dialog.component.css'],
	imports: [TableModule, DialogModule],
})
export class SubpatternDialogComponent {

	subPatternsDialog: SelectItem<number>[] = [];

	constructor(
		public dialogRef: DynamicDialogRef,
		public config: DynamicDialogConfig,
	) {
		this.subPatternsDialog = config.data.subPatterns;
	}

	onSubpatternSelected(subpattern: SelectItem<number>) {
		this.dialogRef.close(subpattern);
	}
}
