export enum MatchMode {
	And = 'AND',
	Between = 'BETWEEN',
	Contains = 'CONTAINS',
	ContainsCi = 'CONTAINS_CI',
	Custom = 'CUSTOM',
	Empty = 'EMPTY',
	EndsWith = 'ENDS_WITH',
	EndsWithCi = 'ENDS_WITH_CI',
	Equals = 'EQUALS',
	EqualsCi = 'EQUALS_CI',
	EqualsOrNull = 'EQUALS_OR_NULL',
	GreaterThan = 'GREATER_THAN',
	GreaterThanOrEqualsTo = 'GREATER_THAN_OR_EQUALS_TO',
	In = 'IN',
	InOrEmpty = 'IN_OR_EMPTY',
	IsNotNull = 'IS_NOT_NULL',
	IsNull = 'IS_NULL',
	LessThan = 'LESS_THAN',
	LessThanOrEqualsTo = 'LESS_THAN_OR_EQUALS_TO',
	Like = 'LIKE',
	LikeCi = 'LIKE_CI',
	NotContains = 'NOT_CONTAINS',
	NotEmpty = 'NOT_EMPTY',
	NotEquals = 'NOT_EQUALS',
	Or = 'OR',
	StartsWith = 'STARTS_WITH',
	StartsWithCi = 'STARTS_WITH_CI'
}

export enum PatternType {
	Conclusion = 'CONCLUSION',
	Description = 'DESCRIPTION',
	DoctorData = 'DOCTOR_DATA',
	FiscalData = 'FISCAL_DATA',
	Footer = 'FOOTER',
	Header = 'HEADER',
	Images = 'IMAGES',
	InvoiceData = 'INVOICE_DATA',
	Main = 'MAIN',
	PatientData = 'PATIENT_DATA',
	Recommendation = 'RECOMMENDATION',
	Result = 'RESULT',
	Spacer = 'SPACER',
	Title = 'TITLE'
}

export enum PatternModel {
	All = 'ALL',
	Exam = 'EXAM',
	ExamEmail = 'EXAM_EMAIL',
	Invoice = 'INVOICE',
	InvoiceEmail = 'INVOICE_EMAIL',
	ReceiptEmail = 'RECEIPT_EMAIL',
	Receipt = 'RECEIPT',
	System = 'SYSTEM'
}

export type Pattern = {
	id: number;
	name: string;
	type?: PatternType;
	model?: PatternModel;
	content?: string;
};

export type Placeholder = {
	id: string;
	values?: PlaceholderValue[];
};

export type PlaceholderValue = {
	id: number;
	label?: string;
	position?: number;
	value?: string;
};

export type ImageDto = {
	id?: number;
	full?: string;
	thumbnail?: string;
	name?: string;
	fileName?: string;
};

export type FileDto = {
	content: any;
	contentType: string;
	filename: string;
};

export enum ImageGroupSubtype {
	ClinicSignature = 'CLINIC_SIGNATURE',
	DoctorAvatar = 'DOCTOR_AVATAR',
	ExaminerSignature = 'EXAMINER_SIGNATURE',
	Exams = 'EXAMS',
	Logos = 'LOGOS',
	Unique = 'UNIQUE',
	UserAvatar = 'USER_AVATAR'
}

export type EntityInterface = {
	id?: any;
}
