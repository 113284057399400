import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractForm } from '../abstract-form';
import { ButtonModule } from 'primeng/button';

@Component({
	standalone: true,
	selector: 'br-form-buttons',
	templateUrl: './form-buttons.component.html',
	imports: [NgClass, ButtonModule],
})
export class FormButtonsComponent {
	@Input()
	styleClass: string | object;

	constructor(protected form: AbstractForm<any>) {}
}
