import { NgClass } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';

import { AbstractFilterComponent } from '../abstract/abstract-filter.component';

@Component({
	standalone: true,
	selector: 'br-dropdown-filter',
	templateUrl: './dropdown-filter.component.html',
	imports: [NgClass, FormsModule, DropdownModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownFilterComponent),
			multi: true,
		},
	],
})
export class DropdownFilterComponent extends AbstractFilterComponent {
	@Input()
	label: string = '';

	@Input()
	required: boolean = false;

	@Input()
	name: string = '';

	@Input()
	items: SelectItem[] = [];
}
