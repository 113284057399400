import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags',
  standalone: true,
})
export class StripTagsPipe implements PipeTransform {

	public transform(value: any): string {
		return StripTagsPipe.format(value);
	}

	public static format(value: any) {
		return value.replace(/<[^>]+>/ig,"");
	}

}
